import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import SubmitWarrantyBtn from "./Warranty/SubmitWarrantyBtn";

export default function Hero({ img, accountType }) {
  const firstName = sessionStorage.getItem("firstName");
  return (
    <Container className="m-0">
      <Row xs={1} lg={2} className="me-auto">
        <Col className="ps-30 pe-30 ps-md-50 pe-0 align-self-center">
          <span className="fs-30 text-secondary">Welcome, {firstName}, to</span>
          <div className="mb-40">
            <Image src="/mysm_logo_blue.png" height="60" />
          </div>
          <p className="text-primary">
            The information in this homebuyer portal will help to guide you through your new home purchase and beyond. Review details about your home and neighborhood, submit warranty requests, and contact your warranty team.
          </p>
          {accountType === "3" && (
            <>
              <h3 className="mt-50">Warranty</h3>
              <p className="mb-30">
                View coverage information, important documents
                <br />
                and submit and view your warranty requests.
              </p>
              <SubmitWarrantyBtn />
            </>
          )}
        </Col>
        <Col>
          <Image
            alt="MyStanleyMartin"
            width={620}
            height={509}
            className="img-fluid"
            src={img}
          />
        </Col>
      </Row>
    </Container>
  );
}
