import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AppRoutes, AppPageRoutes } from "./AppRoutes";
import { useLocation } from "react-router-dom";
import "../src/styles/app.css";

export default function App() {
  let isValid = sessionStorage.getItem("isValid");
  let accountType = sessionStorage.getItem("accountType");

  //Login Redirect Handler
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
      if (pathname === "/") {
          if (isValid === "True") {
              if (accountType === "1") {
                  navigate("/my-home");
              } else if (accountType === "2") {
                  navigate("/my-homes");
              } else {
                  navigate("/warranty");
              }
          }
    } else if (isValid === "false" || isValid === null) {
      navigate("/");
      }
  }, [pathname, isValid, navigate, accountType]);
  return (
    <Routes>
      {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      {(AppPageRoutes[accountType] && AppPageRoutes[accountType].map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      }))}
    </Routes>
  );
}
