import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Page from "../components/Layout/Page";
import { useLocation } from "react-router-dom";
export default function DocumentPreview() {
  const location = useLocation();
  const pdfLink = location.state.data;
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Document Preview - Home Resources | My Stanley Martin</title>
      </Helmet>
      <Container className="px-30 px-md-50 pb-50" fluid>
        <Container className="m-0 p-0 h-100">
          <embed src={pdfLink} width="100%" height="780" />
        </Container>
      </Container>
    </Page>
  );
}
