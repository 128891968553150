import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";

export default function NavMenuClassic({ showIcons }) {
  return (
    <>
      <Nav.Link href="/warranty" className="mb-40 py-0">
        <Row>
          <Col className="d-flex justify-content-start">Warranty</Col>
          {showIcons === true && (
            <Col className="d-flex justify-content-end align-self-center">
              <FaChevronRight />
            </Col>
          )}
        </Row>
      </Nav.Link>
      <Nav.Link href="/contact-us" className="mb-40 py-0">
        Contact Us
      </Nav.Link>
          {/*<Nav.Link href="/our-partners" className="mb-40 py-0">
        Our Partners
      </Nav.Link>*/}
    </>
  );
}
