import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import RegisterModal from "./RegisterModal";

export default function LoginForm({
  formRef,
  validated,
  handleSubmit,
  userPassword,
  showPasswordInput,
  handleUserPassword,
  userEmail,
  handleUserEmail,
  errorMessage,
  showErrorMessage,
}) {
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const [showUserPassword, setShowUserPassword] = useState(false);
  const togglePassword = () => {
    setShowUserPassword(!showUserPassword);
  };

  return (
    <>
      <Form
        className="text-center mt-25"
        noValidate
        ref={formRef}
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Form.Label className="d-none">Email Address</Form.Label>
        <Form.Control
          id="userEmail"
          className="p-15 rounded-3 border border-2 border-secondary"
          type="email"
          minLength={5}
          autoComplete="off"
          value={userEmail}
          onChange={handleUserEmail}
          placeholder="Email Address*"
          required
          readOnly={showPasswordInput && true}
          isInvalid={
            showErrorMessage === true && errorMessage === "Invalid email format"
          }
        />
        {showPasswordInput && (
          <Form.Group controlId="userPassword">
            <InputGroup className="mt-20 border border-2 rounded-3 border-secondary">
              <Form.Control
                className="p-15 rounded-left border-0"
                type={showUserPassword ? "text" : "password"}
                value={userPassword}
                onChange={handleUserPassword}
                placeholder="Enter Password*"
                autoComplete="on"
                required
              />
              <InputGroup.Text
                className="rounded-right cursor-pointer bg-white text-grey"
                onClick={togglePassword}
              >
                {showUserPassword ? <FaEye /> : <FaEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        )}
        {showErrorMessage && (
          <span className="text-red fw-bold mt-20 d-block pt-0 fs-15">
            {errorMessage}
          </span>
        )}
        <Button size="lg" className="mt-25" variant="success" type="submit">
          {showPasswordInput ? "SIGN IN" : "CONTINUE"}
        </Button>
      </Form>
      <RegisterModal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        useremail={userEmail}
        parentmodalprops={""}
      />
    </>
  );
}
