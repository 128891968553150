import React, { useState } from "react";
import { Button, Image, Navbar } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavBarAccount from "./NavBarAccount";
import NavBarOffCanvas from "./NavBarOffCanvas";
import { rootImagePath } from "../../lib/Helpers";
import { FaBars } from "react-icons/fa";

export default function NavBar() {
  let isValid = sessionStorage.getItem("isValid");

  // Account Type = 1 -> "Existing User" & FinancialInstanceID not NULL
  // Account Type = 2 -> "Investor Profile" 
  // Account Type = 3 -> "New Registration"
  const accountType = sessionStorage.getItem("accountType");

  let logo = "";
  if (isValid === "True") {
      logo = `${rootImagePath}/stanleymartin_logo_stacked.png`;
  } else {
      logo = `${rootImagePath}/stanleymartin_logo_horizontal.svg`;
  }

  const containerProps =
    isValid === "True" ? { fluid: true } : { fluid: false };
  const logoSize = isValid === "True" ? "70px" : "80px";

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <header>
          <Navbar fixed="top" variant="dark" bg="primary" className={`${isValid === "True" ? "py-md-20" : "py-md-10"}`}>
        <Container className="px-0 pe-15" {...containerProps}>
          <Navbar.Brand className="ms-10 ms-md-30">
            <Button
              variant="link"
              className="p-0 bg-transparent"
              href={
                isValid
                    ? accountType === "1"
                        ? "/my-home"
                        : accountType === "2"
                            ? "/my-homes"
                            : "/warranty"
                    : "https://www.stanleymartin.com/homebuyer-resources/warranty"
              }
              target={isValid === "True" ? "_parent" : "_blank"}
            >
              <Image
                alt="MyStanleyMartin"
                className="img-fluid p-5 p-md-0"
                src={logo}
                style={{
                  width: "auto",
                  height: logoSize,
                }}
              />
            </Button>
          </Navbar.Brand>
          <Nav className="ms-auto pe-15 pe-md-130">
            {isValid === "True" && (
              <>
                {/*<Nav.Link as="div" className="py-0 pe-0 cursor-pointer">
                   Need props to render user alerts array 
                  <NavBarAlerts />
                </Nav.Link>*/}
                <Nav.Link as="div" className="py-0 cursor-pointer">
                  <NavBarAccount />
                </Nav.Link>
              </>
            )}
            <Nav.Link
              onClick={toggleShow}
              className="text-white d-block d-sm-block d-md-none me-n25"
              style={{
                marginTop: -3,
              }}
            >
              <FaBars size={26} />
            </Nav.Link>
          </Nav>
          <NavBarOffCanvas logoSrc={logo} show={show} onHide={handleClose} />
        </Container>
      </Navbar>
    </header>
  );
}
