import { useEffect, useState } from "react";

const useNameAbbr = () => {
  const [nameAbbr, setNameAbbr] = useState("");

  useEffect(() => {
    const getInitials = () => {
      let firstName = sessionStorage.getItem("firstName");
      let lastName = sessionStorage.getItem("lastName");

      if (firstName) {
        const firstNameLength = firstName.length;
        const lastNameLength = lastName ? lastName.length : 0;
        if (firstNameLength > 0) {
          return (
            firstName.charAt(0).toUpperCase() +
            (lastNameLength >= 1 ? lastName.charAt(0).toUpperCase() : "")
          );
        }
      }
      return "";
    };

    setNameAbbr(getInitials());
  }, []);

  return nameAbbr;
};

export default useNameAbbr;
