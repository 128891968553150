import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Image, Row } from "react-bootstrap";
import Page from "../components/Layout/Page";
import InvestorHomeTable from "../components/Investor/InvestorHomeTable";
import { investorHomeDetailsApi } from "../lib/investorApi";

export default function Investor() {

    const email = sessionStorage.getItem("email");
    const firstName = sessionStorage.getItem("firstName");
    const [homes, setHomes] = useState([]);

    useEffect(() => {
        investorHomeDetailsApi(email)
            .then((res) => {
                setHomes(res);
            })
            .catch((e) => { });
    }, [email]);
    return (
        <>
            
            <Page>
            
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>My Homes | My Stanley Martin</title>
                </Helmet>

                <Container fluid className="m-0 pe-0">

                    {/* Static Content */}
                    <Row xs={1} lg={2} className="me-auto">
                        <Col className="ps-30 pe-30 ps-md-50 pe-0 align-self-center">
                            <Row className="me-auto">
                                <span className="fs-30 text-secondary">Welcome, {firstName}, to</span>
                                <div className="mb-40">
                                    <Image src="/mysm_logo_blue.png" height="60" />
                                </div>
                                <p className="text-primary">
                                    As a valued customer with multiple purchases, we're dedicated to enhancing your experience by providing a seamless and convenient process for accessing information about your homes and submitting warranty requests. All you need to do is select one of your homes and explore the myStanleyMartin experience.
                                </p>
                            </Row>
                        </Col>
                        <Col className="px-0">
                            <Image
                                alt="MyStanleyMartin"
                                /*width={620}*/
                                /*height={509}*/
                                className="img-fluid"
                                src="/images/investor/mysm_homes_dumfries.jpg" /* create variable for image URL if needed */
                            />
                        </Col>
                    </Row>

                    {/* Homes Table */}
                    <Row className="me-auto">
                        <Col className="me-auto px-0">
                            <Col className="ps-15 ps-md-50">
                                <h3>My Homes</h3>
                            </Col>
                            <Col className="ps-0 pe-0 ps-md-0 pb-50 mt-0">
                                <InvestorHomeTable investorHomes={homes} />
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Page>
        </>
    );
}
