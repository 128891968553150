import React from "react";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Page from "../components/Layout/Page";
import WarrantyContent from "../components/Warranty/WarrantyDetails";
import SubmitWarrantyBtn from "../components/Warranty/SubmitWarrantyBtn";
import Hero from "../components/Hero";

export default function Warranty() {
  window.scrollTo(0, 0);
  let accountType = sessionStorage.getItem("accountType");
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Warranty | My Stanley Martin</title>
      </Helmet>
      {accountType === "3" ? (
        <Hero img="/images/myhome/mysm_hero_image.jpg" accountType={accountType} />
      ) : (
        <Col xs={12} className="px-30 px-md-50 mt-30 me-auto max-view-width">
          <h3>Warranty</h3>
          <p className="fs-18 fw-regular">
            View coverage information, important documents and submit and view
            your warranty requests.
          </p>
          <SubmitWarrantyBtn/>
        </Col>
      )}
      <Col xs={12} className="px-30 px-md-50 mt-30 me-auto max-view-width">
        <WarrantyContent/>
      </Col>
    </Page>
  );
}
