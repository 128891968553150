import React from "react";
import { Nav} from "react-bootstrap";
export default function NavMenuLogin() {
  return (
    <>
      <Nav.Link href="https://www.stanleymartin.com" className="mb-40 py-0 text-nowrap">
       Stanley Martin Homes
      </Nav.Link>
      <Nav.Link href="https://www.stanleymartin.com/contact-us" className="mb-40 py-0">
        Contact Us
      </Nav.Link>
    </>
  );
}
