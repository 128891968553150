import React, { useState, useEffect } from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import {
  FaBriefcase,
  FaBuilding,
  FaClock,
  FaCopy,
  FaPowerOff,
} from "react-icons/fa";
import { rootImagePath } from "../../lib/Helpers";
import { getWarrantyContactApi } from "../../lib/warrantyApi";

export default function HistoricUser() {
  const [contactNumber, setContactNumber] = useState(false);
  const recID = sessionStorage.getItem("recID");
  const accountType = sessionStorage.getItem("accountType");
  const email = accountType === "2" ? sessionStorage.getItem("email") : null;
    // If accountType = 2 (investor) then fetch financialInstanceID from the local storage else fetch financialInstanceID from session storage
  const financialInstanceID = accountType === "2" ? localStorage.getItem("financialInstanceID") : sessionStorage.getItem("userFinancialId");
  useEffect(() => {
    getWarrantyContactApi(recID, financialInstanceID, email)
      .then((res) => {
        setContactNumber(res.phone);
      })
      .catch((e) => {});
  }, [recID, financialInstanceID, email]);
  return (
    <Col xs={12} className="pb-50">
      <h3 className="mt-40 mb-30">In Case of an Emergency</h3>
      <p className="mb-0">
        If you are experiencing flooding, potential safety or fire hazards or no
        heat during winter please call{" "}
        <span className="fw-bold">{contactNumber}</span>.
        <br />
        If you suspect a gas leak, contact your gas company immediately.
      </p>

      <Row className="mt-50">
        <h3 className="mb-30">Your New Home Warranty</h3>
        <Col sm={12} className="text-start align-self-center">
          <Image
            width={273}
            height={83}
            src={`${rootImagePath}/images/myhome/mysm_warranty_logo_2_10.png`}
            className="img-fluid my-15"
          />
        </Col>
        <Col>
          <p>
            <a
              className="fw-bold"
              target="_blank"
              href="https://ik.imagekit.io/p40fshsib/mysm/home/documents/2-10_warranty.pdf"
              rel="noreferrer"
            >
              Click here to view and read your home warranty.
            </a>
          </p>
          <p className="fs-14">
            <i>
                Stanley Martin homeowners who signed contracts prior to 12/1/2023 and homeowners who signed contracts with companies whose assets
                were purchased by Stanley Martin may have received a different warranty document than our current home warranty.
                Each homeowner should refer to the warranty documentation the homeowner received at the time of contract and at closing.
             </i>
          </p>
        </Col>
      </Row>
      <h3 className="mt-50 mb-30">Our Warranty at a Glance</h3>
      <p className="text-dark mt-3">
        Below is a high-level summary of each component of our New Home Warranty
        Program. The details of the coverage, including the Construction
        Performance Guidelines that establish the warranty standards for various
        portions of the home, are set out in the warranty document.
      </p>
      <Row xs={1} md={3}>
        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaBriefcase size={41} className="mb-25 text-success" />
              <h5 className="text-dark">1 Year Warranty</h5>
              <h6 className="fst-italic text-dark mb-20">Workmanship</h6>
              <p className="mb-0">
                This warranty covers components such as the roof, counter tops,
                doors, windows and finished floor surfaces.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaPowerOff size={41} className="mb-25 text-success" />
              <h5 className="text-dark">2 Year Warranty</h5>
              <h6 className="fst-italic text-dark mb-20">
                Distribution Systems
              </h6>
              <p className="mb-0">
                This warranty covers the electrical, plumbing, and mechanical
                distribution systems in the home.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaBuilding size={41} className="mb-25 text-success" />
              <h5 className="text-dark">10 Year Warranty</h5>
              <h6 className="fst-italic text-dark mb-20">Structural</h6>
              <p className="mb-0">
                This warranty covers the designated loadbearing elements of the
                home, such as the foundation system, floor framing system,
                load-bearing walls and roof framing.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaCopy size={41} className="mb-25 text-success" />
              <h5 className="mb-20 text-dark">Manufacturer's Warranty</h5>
              <p className="mb-0">
                Many parts of a new home are backed by a separate
                manufacturer&rsquo;s warranty. These include kitchen appliances,
                plumbing fixtures like faucets, roof shingles, HVAC equipment
                and more. Claims should be made through that
                manufacturer&rsquo;s warranty program.
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaClock size={41} className="mb-25 text-success" />
              <h5 className="mb-20 text-dark">60-DAY Warranty Inspection</h5>
              <p className="mb-0">
                At your 60 day warranty inspection, we will address any minor
                adjustments that need to be made to your new home. This
                appointment will be scheduled by your Neighborhood Construction
                Manager on your closing day. Before your inspection, compile a
                list of concerns and submit your list through our Homebuyer
                Portal. Once we receive your list, we will contact you to
                confirm the scheduled date and time of your inspection.
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="shadow-sm rounded-4 border-secondary-10 mt-30">
            <Card.Body className="text-center p-20">
              <FaCopy size={41} className="mb-25 text-success" />
              <h5 className="mb-20 text-dark">One-Time Drywall Service</h5>
              <p className="mb-0">
                All new homes may experience drywall nail pops and cracks due to
                normal settlement. We will address visible nail pops and
                significant drywall cracks one time during the first year.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h3 className="mt-55 mb-40">Frequently Asked Questions</h3>
      <h4 className="mb-15">
        Who do I contact if something is wrong with my home?
      </h4>
      <p className="mb-40">
        In between your closing day and your 60-day inspection, make sure to
        submit your warranty requests prior to your 60-day inspection taking
        place. If you have an emergency (you have a plumbing or electrical
        issue) consult the professionals listed on your Trade Partner Contact
        List or contact your warranty team at
        <span className="fw-bold ms-5">{contactNumber}.</span>
      </p>

      <h4 className="mb-15">What are nail pops? How do I fix them?</h4>
      <p className="mb-40">
        Nails pops are where the popped nail pushes out a bit of drywall or
        paint, creating a small bump or crack in walls or ceilings. They develop
        when a home settles into place as it goes through the changing of the
        seasons. The good news is you're not alone. All homes have nail pops
        (old and new) and they can easily be fixed. As you are getting settled
        into your new home, make sure to note any nail pops or drywall cracks
        that appear. Before your 1-year warranty is over, you will request a
        service appointment for repairs. We recommend contacting the Warranty
        team around the 10 month mark to schedule your appointment.
      </p>

      <h4 className="mb-15">
        Do I have to register my appliances in order to be eligible for the manufacturer's warranty?
      </h4>
      <p className="mb-0">
        Yes, it is a good idea to register your appliances with the
        manufacturers. By registering your appliances, you provide the
        manufacturer with important information such as the date of purchase,
        model number, and warranty information. This makes it easier for them to
        provide support and service if needed, especially during the warranty
        period. Registering your appliances can also help with any recalls or
        safety alerts that may be issued in the future. If a recall or alert is
        issued, the manufacturer can easily contact you and provide instructions
        on what to do next. You can typically register your appliances online or
        by mail, and the process only takes a few minutes.
      </p>
    </Col>
  );
}
