import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AddressHeader({ value }) {
  return (
    <Col xs={3} className="pt-10 pb-10 fw-bold text-center">
      {value}
    </Col>
  );
}
function AddressCol({ value, index }) {
  return (
    <Col xs={3} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
      {value}
    </Col>
  );
}

function CommunityNameHeader({ value }) {
  return (
    <Col xs={3} className="pt-10 pb-10 fw-bold text-center">
      {value}
    </Col>
  );
}
function CommunityNameCol({ value, index }) {
  return (
    <Col xs={3} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
      {value}
    </Col>
  );
}

function LotHeader({ value }) {
  return (
    <Col xs={1} className="pt-10 pb-10 fw-bold text-center">
      {value}
    </Col>
  );
}
function LotCol({ value, index }) {
  return (
    <Col xs={1} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
      {value}
    </Col>
  );
}

function HousePlanHeader({ value }) {
  return (
    <Col xs={1} className="pt-10 pb-10 fw-bold text-center">
      {value}
    </Col>
  );
}
function HousePlanCol({ value, index }) {
  return (
    <Col xs={1} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
      {value}
    </Col>
  );
}

function ElevationHeader({ value }) {
    return (
        <Col xs={1} className="pt-10 pb-10 fw-bold text-center">
            {value}
        </Col>
    );
}
function ElevationCol({ value, index }) {
    return (
        <Col xs={1} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
            {value}
        </Col>
    );
}

function SubmitWarrantyRequestHeader({ value }) {
    return (
        <Col xs={3} className="pt-10 pb-10 fw-bold text-center">
            {value}
        </Col>
    );
}
function SubmitWarrantyRequestCol({ value, index }) {
    const navigate = useNavigate();
    const handlerSubmitWarrantyRequestCol = (financialInstanceID) => {
        localStorage.setItem("financialInstanceID", financialInstanceID);
        navigate("/warranty");
    };
    return (
        <Col xs={3} className={(index % 2 === 0 ? "bg-white" : "bg-light") + " py-10 text-center"}>
            <Button
                variant="link"
                className="text-primary py-0 px-0"
                type="button"
                onClick={() => handlerSubmitWarrantyRequestCol(value)}
                style={{ textDecoration: 'underline' }}
            >
                Submit Warranty Request
            </Button>
        </Col>
    );
}

export default function InvestorHomeTable({ investorHomes }) {
    const homes = investorHomes;
  return (
    <>
      {homes.length !== 0 && (
        <Row
          xs={6}
          className="bg-light-blue px-0 me-0 pt-0 g-0 gap-0 border border-1 border-secondary-10 table-custom"
        >
          <AddressHeader key="1" value="Address" />
          <CommunityNameHeader key="2" value="Community Name" />
          <LotHeader key="3" value="Lot #" />
          <HousePlanHeader key="4" value="House Plan" />
          <ElevationHeader key="5" value="Elevation" />
          <SubmitWarrantyRequestHeader key="6" value="" />
          {homes &&
            homes.map((home, index) => (
                <React.Fragment key={index} >
                    <AddressCol value={home.address} index={index} />
                    <CommunityNameCol value={home.communityName} index={index} />
                    <LotCol value={home.homesite} index={index} />
                    <HousePlanCol value={home.housePlan} index={index} />
                    <ElevationCol value={home.elevation} index={index} />
                    <SubmitWarrantyRequestCol value={home.financialInstanceID} index={index} />
              </React.Fragment>
            ))}
        </Row>
      )}
    </>
  );
}
