import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { generateTempPasswordApi, passwordResetApi } from "../../lib/mysmhApi";
import { SessionContext } from "../../SessionProvider";

export default function TempPasswordForm({ useremail }) {
  const [validated, setValidated] = useState(false);
  const { updateSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const [userTempPassword, setUserTempPassword] = useState("");
  const [userNewPassowrd, setUserNewPassword] = useState("");

  const [errorTempPassMessage, setErrorTempPassMessage] = useState("");
  const [errorNewPassMessage, setErrorNewPassMessage] = useState("");

  const [successTempPassMessage, setSuccessTempPassMessage] = useState("");
  const [showGenPwButton, setShowGenPwButton] = useState(false);

  const formRef = useRef(null);

  //set temp message
  useEffect(() => {
    setShowGenPwButton(true);
    setSuccessTempPassMessage("to send a temporary password to your email.");
  }, []);

  //Trigger generate temp
  const handleTempPasswordReset = async (event) => {
    event.preventDefault();
    await generateTempPasswordApi(useremail);
    setShowGenPwButton(false);
    setSuccessTempPassMessage(
      "A temporary password has been sent to your email! Enter the password below."
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const email = useremail;
    const passwordtemp = form.elements.userTempPassword?.value;
    const password = form.elements.userNewPassword?.value;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (password !== "" && passwordtemp !== "") {
      //Reset User Account Password
      setValidated(true);
      const passwordReset = await passwordResetApi(
        email,
        passwordtemp,
        password
      );
      if (passwordReset && passwordReset.isAuthenticated === "True") {
        const accountType = passwordReset.accountType;
        const userProps = {
          email: passwordReset.email,
          firstName: passwordReset.firstName,
          lastName: passwordReset.lastName,
          userFinancialId: accountType === "2" ? null : passwordReset.userFinancialId,
          isValid: passwordReset.isAuthenticated,
          recID: passwordReset.recID,
          profileImageUrl: passwordReset.profileImageUrl,
          accountType: passwordReset.accountType
        };
        updateSession(
          passwordReset.recID,
          passwordReset.projectGroupID,
          passwordReset.email,
          passwordReset.firstName,
          passwordReset.lastName,
          accountType === "2" ? null : passwordReset.userFinancialId,
          passwordReset.isAuthenticated,
          passwordReset.profileImageUrl,
          passwordReset.accountType
        );
        if (accountType === "1"){
            navigate("/my-home", { state: userProps });
        } else if (accountType === "2") {
            navigate("/my-homes", { state: userProps });
        } else {
            navigate("/warranty", { state: userProps });
        }
      } else {
        setErrorTempPassMessage("Wrong Temp password");
      }
    }
    if (passwordtemp.length === 0) {
      setErrorTempPassMessage("Enter Temp password");
    }
    if (password.length === 0) {
      setErrorNewPassMessage("Enter New password");
    }
  };

  const [showTempPassword, setShowTempPassword] = useState(false);
  const toggleTempPassword = () => {
    setShowTempPassword(!showTempPassword);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <Form
      className="text-center"
      noValidate
      ref={formRef}
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Col>
        {showGenPwButton && (
          <Button
            type="button"
            variant="link"
            alt="Resent temp password"
            className="text-white link-accent text-decoration-underline p-0 me-5 mt-n5"
            onClick={handleTempPasswordReset}
          >
            Click here
          </Button>
        )}
        {successTempPassMessage}
      </Col>

      <Form.Group controlId="userEmailAddress">
        <Form.Control
          className="p-15 rounded-3 border border-2 border-secondary mt-20 text-grey bg-light"
          type="email"
          name="userEmailAddress"
          defaultValue={useremail}
          placeholder="Email Address*"
          readOnly
        />
        <Form.Control.Feedback
          type="invalid"
          className="text-red fs-15 mb-0 mt-15 fw-bold"
        >
          Enter Email Address
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="userTempPassword">
        <InputGroup className="mt-20 border border-2 rounded-3 border-secondary">
          <Form.Control
            name="userTempPassword"
            className={`p-15 rounded-left border-0 ${
              !validated && "is-invalid"
            }`}
            type={showTempPassword ? "text" : "password"}
            value={userTempPassword}
            onChange={(event) => setUserTempPassword(event.target.value)}
            placeholder="Temp Password"
            required
          />
          <InputGroup.Text
            className="rounded-right cursor-pointer bg-white text-grey"
            onClick={toggleTempPassword}
          >
            {showTempPassword ? <FaEye /> : <FaEyeSlash />}
          </InputGroup.Text>
        </InputGroup>
        {errorTempPassMessage && (
          <Col className="text-red fs-15 mb-0 mt-15 fw-bold">
            {errorTempPassMessage}
          </Col>
        )}
      </Form.Group>

      <Form.Group controlId="userNewPassword">
        <InputGroup className="mt-20 border border-2 rounded-3 border-secondary">
          <Form.Control
            className={`p-15 rounded-left border-0 ${
              !validated && "is-invalid"
            }`}
            type={showNewPassword ? "text" : "password"}
            name="userNewPassword"
            value={userNewPassowrd}
            onChange={(event) => setUserNewPassword(event.target.value)}
            placeholder="New Password"
            required
          />
          <InputGroup.Text
            className="rounded-right cursor-pointer bg-white text-grey"
            onClick={toggleNewPassword}
          >
            {showNewPassword ? <FaEye /> : <FaEyeSlash />}
          </InputGroup.Text>
        </InputGroup>

        {errorNewPassMessage && (
          <Col className="text-red fs-15 mb-0 mt-15 fw-bold">
            {errorNewPassMessage}
          </Col>
        )}
      </Form.Group>

      <Button className="mt-45 btn-lg" variant="success" type="submit">
        SIGN IN
      </Button>
    </Form>
  );
}
