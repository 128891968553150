import React from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import SiteName from "../SiteName";

export default function ForgotPasswordConfirmModal(props) {
  const handleTempModal = async () => {
    props.onHide();
    props.showpasswordinput();
    props.showpasswordtempmodal();
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
    >
      <Modal.Body className="text-bg-primary text-center rounded-4 shadow-lg pb-40 px-5 pt-0 fs-20">
        <Modal.Header className="text-white pb-45">
          <Col className="justify-content-start align-self-center" />
          <Col className="m-auto text-center align-self-center d-flex" />
        </Modal.Header>
        <Row>
          <Col xs={12}>
            <SiteName />
          </Col>
          <Col xs={12}>
            <p className="mb-0 fs-20 fw-bold border rounded-4 p-30 mx-50 border-1 border-white">
              A temp password has been sent to {props.useremail}
            </p>
          </Col>
        </Row>
        <Button
          type="button"
          variant="success"
          size="md"
          className="mt-30"
          onClick={handleTempModal}
        >
          CONTINUE
        </Button>
      </Modal.Body>
    </Modal>
  );
}
