import React from "react";
import { Button, Card } from "react-bootstrap";
import { FaFileAlt, FaAddressBook, FaMapMarkerAlt } from "react-icons/fa";
import SubmitWarrantyBtn from "../Warranty/SubmitWarrantyBtn";

export default function HomeScheduleCard() {
  let projectGroupId = sessionStorage.getItem("projectGroupID");
  return (
    <Card
      id="homeScheduleCard"
      className="mt-30 shadow shadow-sm border-secondary-10 rounded rounded-4"
    >
      <Card.Body>
        <SubmitWarrantyBtn />

        <Button
          className="btn-warranty border border-2 text-primary shadow-sm rounded border-secondary-10 w-100 fs-16 text-start mt-20 py-15"
          href="/home-resources"
        >
          <FaFileAlt className="text-secondary mt-n5 me-5" size={16} />
          <span>Home Resource Documents</span>
        </Button>

        <Button
          className="btn-warranty border border-2 text-primary shadow-sm rounded border-secondary-10 w-100 fs-16 text-start mt-20 py-15"
          href="/contact-us"
        >
          <FaAddressBook className="text-secondary mt-n5 me-5" size={16} />
          <span>Your Warranty Contact</span>
        </Button>

        {projectGroupId !== null && (
          <Button
            className="btn-warranty border border-2 text-primary shadow-sm rounded border-secondary-10 w-100 fs-16 text-start mt-20 py-15"
            href="/neighborhood"
          >
            <FaMapMarkerAlt className="text-secondary mt-n5 me-5" size={16} />
            <span>Neighborhood Map</span>
          </Button>
        )}

        {/* <Col className="border p-40 shadow-sm rounded rounded-2 border-secondary-10">
            <Row>
              <Col
                xs={3}
                md={2}
                className="text-center border border-1 border-end border-start-0 border-top-0 border-bottom-0"
              >
                <span className="text-white fs-14 fw-bold">JUN</span>
                <br />
                <span className="text-accent fw-bold fs-25 mt-n10 d-block">
                  25
                </span>
              </Col>
              <Col className="align-self-center">
                <Row>
                  <span className="text-white fs-14 fw-bolder">
                    Design Studio Meeting
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>*/}

        {/*<Col className="border mt-20 fs-16 p-15 shadow-sm rounded rounded-2 border-secondary-10 quick-link">
          <Row>
          <Col
                xs={3}
                md={2}
                className="text-center border border-1 border-end border-start-0 border-top-0 border-bottom-0"
              >
                <span className="text-primary fw-bold fs-14">JUN</span>
                <br />
                <span className="text-accent fw-bold fs-25 mt-n10 d-block">
                  10
                </span>
              </Col>
              <Col className="align-self-center">
                <Row>
                  <span className="text-primary fs-14 fw-bolder">
                    Pre-Construction Meeting
                  </span>
                </Row>
              </Col>
          </Row>
        </Col>*/}

        {/*<Col className="border fs-16 mt-20 p-15 shadow-sm rounded rounded-2 border-secondary-10 quick-link">
          <Row>
            <Col
                xs={3}
                md={2}
                className="text-center border border-1 border-end border-start-0 border-top-0 border-bottom-0"
              >
                <span className="text-primary fw-bold fs-14">JUN</span>
                <br />
                <span className="text-accent fw-bold fs-25 mt-n10 d-block">
                  10
                </span>
              </Col>
              <Col className="align-self-center">
                <Row>
                  <span className="text-primary fs-14 fw-bolder">
                    Pre-Construction Meeting
                  </span>
                </Row>
              </Col>
          </Row>
        </Col>*/}

        {/*<Col className="border fs-16 mt-20 p-15 shadow-sm rounded rounded-2 border-secondary-10 quick-link">
          <Row>
            <Col
                xs={3}
                md={2}
                className="text-center border border-1 border-end border-start-0 border-top-0 border-bottom-0"
              >
                <span className="text-primary fw-bold fs-14">JUN</span>
                <br />
                <span className="text-accent fw-bold fs-25 mt-n10 d-block">
                  10
                </span>
              </Col>
              <Col className="align-self-center">
                <Row>
                  <span className="text-primary fs-14 fw-bolder">
                    Pre-Construction Meeting
                  </span>
                </Row>
              </Col>
          </Row>
        </Col>*/}
      </Card.Body>

      {/* <Col className="text-center px-40 position-relative">
          <p className="fs-12 text-grey mt-0 mt-md-245 mt-lg-190">
            <br />
          </p>
        </Col> */}
    </Card>
  );
}
