import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaHouseUser, FaCalendarAlt, FaTag, FaSquare } from "react-icons/fa";

export default function HomeStatusCard({ props }) {
  return (
    <Card
      id="homeStatusCard"
      className="text-white shadow-sm my-30 p-20 rounded rounded-4"
      style={{
        background: "linear-gradient(-180deg, #325D70, #265466)",
      }}
    >
      <Card.Header className="px-0 fs-16 border-0 bg-transparent">
        Last Milestone <br />
        <strong className="text-success fs-20">{props?.lastMilestone}</strong>
        <br />
        Completed {props?.lastMilestoneEndDate}
      </Card.Header>
      <Card.Img
        width={210}
        height={210}
        style={{
          display: "flex",
          alignContent: "center",
          objectFit: "contain",
        }}
        src={props?.currentStatusImage}
      />
      <Card.Body className="px-0">
        Important Dates
        {/* Sale */}
        {props?.saleDate !== "" && props?.saleDate !== null ? (
          <Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaTag size={20} />
            </Col>
            <Col className="px-0">
              <Row className="fs-16">
                <span className="text-accent fw-bold">Contract Date</span>
                <span>{props?.saleDate}</span>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* Stakeout */}
        {props?.saleDate !== "" && props?.startDate !== null ? (
          <Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaCalendarAlt size={20} />
            </Col>
            <Col className="px-0 fs-16">
              <Row>
                <span className="text-accent fw-bold">Start Date</span>
                <span className="">{props?.startDate}</span>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* Slab Start*/}
        {/* <Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaBorderStyle size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Slab Start</span>
                <span>{props?.pourSlabDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Frame Start */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <MdOutlineFoundation size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Frame Start</span>
                 <span>{props?.frameStartDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Roof Paper */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <MdRoofing size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Roof Paper</span>
                  <span>{props?.roofPaperDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* HVAC Rough-In */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <MdHvac size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">HVAC Rough-In</span>
                  <span>{props?.HVACRoughInDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Stock Drywall */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <GiBrickWall size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Stock Drywall</span>
                  <span>{props?.stockDrywallDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Sand Drywall */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <GiStoneWall size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Sand Drywall</span>
                 <span>{props?.sandDrywallDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Install Cabinets */}
        {/* <Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <BiCabinet size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Install Cabinets</span>
                  <span>{props?.installCabinetsDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Carpet */}
        {/*<Row className="mt-25 d-none">
            <Col xs={2} className="text-accent text-center">
              <GiFloorPolisher size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Carpet</span>
                  <span>{props?.carpetDate}</span>
              </Row>
            </Col>
          </Row>*/}
        {/* Quality Walk */}
        {props?.saleDate !== "" && props?.startDate !== null ? (
          <Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaSquare size={20} />
            </Col>
            <Col className="px-0">
              <Row className="fs-16">
                <span className="text-accent fw-bold">Drywall Complete</span>
                <span>{props?.sandDrywallDate}</span>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* New Home Orientation */}
        {props?.saleDate !== "" && props?.startDate !== null ? (
          <Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaHouseUser size={20} />
            </Col>
            <Col className="px-0">
              <Row className="fs-16">
                <span className="text-accent fw-bold">
                  New Home Orientation
                </span>
                <span>{props?.NewHomeOrientationDate}</span>
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {/* Projected Closing (Settlement) */}
        {/*<Row className="mt-25">
            <Col xs={2} className="text-accent text-center">
              <FaClock size={21} />
            </Col>
            <Col>
              <Row>
                <span className="text-accent">Projected Closing</span>
                 <span>{props?.closingDate}</span>
              </Row>
            </Col>
          </Row>*/}
      </Card.Body>
    </Card>
  );
}
