import React from "react";
import { Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Master from "../components/Layout/Master";
import LoginUser from "../components/Login/LoginUser";
import { coverImage } from "../lib/Helpers";

export default function Login() {
  return (
    <Master>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | MyStanleyMartin</title>
      </Helmet>
      <Container
        fluid
        style={{
          backgroundImage: `url(${coverImage})`,
          backgroundSize: "cover",
          backgroundAttachment: "scroll",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        <Container>
          <Row className="justify-content-md-center align-items-center vh-100 pt-30">
            <LoginUser />
          </Row>
        </Container>
      </Container>
    </Master>
  );
}
