import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import SiteName from "../SiteName";
import TempPasswordModal from "./TempPasswordModal";

export default function RegisterConfirmModal({ show, onHide, useremail }) {
  const [showConfirmAccountModal, setConfirmAccountModal] = useState(false);
  const handleSignIn = () => {
    onHide();
    setConfirmAccountModal(true);
  };
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={onHide}
        size="lg"
        centered
      >
        <Modal.Body className="text-bg-primary text-center fw-bold rounded-4 shadow-lg pb-40 px-5 pt-0 fs-20">
          <Modal.Header className="text-white pb-45">
            <Col className="justify-content-start align-self-center" />
            <Col className="m-auto text-center fw-bold align-self-center d-flex" />
          </Modal.Header>
          <Row>
            <Col xs={12}>
              <SiteName />
            </Col>
            <Col xs={12}>
              <p className="mb-0 fs-18 px-50">
                You will receive an email with a password to sign into your
                account. If you have not received an email please{" "}
                <Button
                  alt="Contact Us"
                  variant="link"
                  className="mt-n5 p-0 text-white text-decoration-underline"
                  href="https://www.stanleymartin.com/contact-us"
                  target="_blank"
                  rel="noreferrer"
                  type="button"
                >
                  visit us on our contact us page
                </Button>
                .
              </p>
            </Col>
          </Row>
          <Button
            type="button"
            variant="success"
            size="md"
            className="mt-30"
            onClick={handleSignIn}
          >
            SIGN IN
          </Button>
        </Modal.Body>
      </Modal>
      <TempPasswordModal
        useremail={useremail}
        show={showConfirmAccountModal}
        onHide={() => setConfirmAccountModal(false)}
      />
    </>
  );
}
