export async function submitWarrantyApi(formData) {
  const requestOptions = {
    method: "POST",
      body: formData,
      headers: {
          'Accept': 'application/json'
      }
    };
    let requestID = "";
    await fetch("mysmh/Warranty/SubmitWarranty", requestOptions)
        .then((response) => response.json())
        .then((responseData) => {
            requestID = JSON.parse(responseData).RequestID;
        });
    return await requestID;
}
export async function submitWarrantyFilesApi(formFiles) {
    const requestOptionsFiles = {
        method: "POST",
        body: formFiles,
    };
    await fetch("mysmh/Warranty/SubmitWarrantyFiles", requestOptionsFiles);
}

export async function getSubmittedWarrantyApi(recID, userFinancialId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      recID,
      userFinancialId,
    }),
  };
  const response = await fetch(
    "mysmh/Warranty/GetSubmittedWarranty",
    requestOptions
  );
  return await response.json();
}

export async function getWarrantyContactApi(recID, userFinancialId, email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      recID,
      userFinancialId,
      email,
    }),
  };
  const response = await fetch(
    "mysmh/Warranty/GetWarrantyContact",
    requestOptions
  );
  return await response.json();
}
