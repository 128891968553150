import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Page from "../components/Layout/Page";
//import Slider from "../components/Slider";
import { neighborhoodDetailsApi } from "../lib/neighborhoodApi";

//{Props} will be removed once api is mapped to content below
export default function Neighborhood() {
  const [neighborhoodDetails, setNeighborhoodDetails] = useState(false);

  useEffect(() => {
    neighborhoodDetailsApi(sessionStorage.getItem("projectGroupID"))
      .then((res) => {
        //User Account Mysm Content userData.users[0] needs to be replaced with getUserAccountApi const
        setNeighborhoodDetails(res);
      })
      .catch((e) => {});
  }, []);

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Neighborhood | My Stanley Martin</title>
        {/* <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLEMAPSAPI}`}
          type="text/javascript"
        /> */}
      </Helmet>
      <Container className="px-30 px-md-50 pb-50" fluid>
        <Container className="m-0 p-0">
          <h2 className="mb-0">{neighborhoodDetails.projectName}</h2>
          {/* <p>
            {neighborhoodDetails.projectAddress},{" "}
            {neighborhoodDetails.projectCity},{" "}
            {neighborhoodDetails.projectState}{" "}
            {neighborhoodDetails.projectPostalCode}
          </p> */}
          <Col className="ms-0 ms-lg-n145 mt-10 mb-60">
            {/*<Slider
              props={props.highlights}
              recordDetails={""}
              showTimeStamp={false}
              showImg={true}
              showText={true}
              fade
              slide={false}
              showBackground
              showIndicators={false}
              showControls={true}
              rowSm={1}
              rowMd={4}
              rowLg={4}
              rowPosition="center"
              buttonText="Read More"
              buttonClassName="bg-white p-0 text-primary fw-bold border border-0 border-bottom border-1 border-primary rounded-0"
            />*/}
          </Col>
          <Row>
            <Col xs={12} md={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: neighborhoodDetails.projectAreaBullets,
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <Col className="embed-responsive embed-responsive-16by9">
                <iframe
                  title="In The Area"
                  className="embed-responsive-item"
                  src={neighborhoodDetails.projectAreaMapLink}
                  style={{ maxHeight: 342, minHeight: 514 }}
                  height="342"
                  width="100%"
                />
              </Col>
            </Col>
          </Row>
          {neighborhoodDetails.sitePlan !== null && (
            <>
              <h3 className="mt-60 mb-30">Neighborhood Map</h3>
              <iframe
                title="In The Area"
                className="embed-responsive-item"
                src={neighborhoodDetails.sitePlan}
                style={{ maxHeight: 668 }}
                height="668"
                width="100%"
              />
            </>
          )}
        </Container>
      </Container>
    </Page>
  );
}
