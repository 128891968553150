import React from "react";
import { Button, Card, Col, Image } from "react-bootstrap";

export default function TeamContactCard({ imgsrc, name, title, phone, email }) {
  return (
    <Card className="shadow border-secondary-10 fs-16">
      <Col className="bg-light-blue text-center rouned-0 rouned-4">
        <Image
          className="rounded-circle m-auto border border-5 border-white mb-n30 mt-20"
          src={imgsrc}
          width={120}
          height={120}
        />
      </Col>
      <Card.Body className="text-center py-20">
        <ul className="list-unstyled mt-10 mb-15">
          <li className="fw-bold">{name}</li>
          <li className="fst-italic fw-regular">{title}</li>
          <li className="fw-bold">{phone}</li>
          <li className="fw-bold">{email}</li>
          <li className="mt-20">
            <Button variant="primary" size="sm" href={`mailto:${email}`}>
              EMAIL
            </Button>
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
}
