import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  FaDollarSign,
  FaFileInvoiceDollar,
  FaWindowClose,
} from "react-icons/fa";
import Page from "../components/Layout/Page";
import UserDepositCalculator from "../components/Account/UserDepositCalculator";
import Slider from "../components/Slider";

// async function userDepositTracker(highlights) {
//   const requestOptions = {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({
//       highlights,
//     }),
//   };
//   const response = await fetch("mysmh/example", requestOptions);
//   return await response.json();
// }

//pass {props} from api to replace the highlights array
export default function DepositeTracker() {
  const [showDepositRecords, setShow] = useState(false);
  const handleCloseDepositRecords = () => setShow(false);
  const handleShowDepositRecords = () => setShow(true);

  const [showDepositSchedule, setShowSchedule] = useState(false);
  const handleCloseDepositSchedule = () => setShowSchedule(false);
  const handleShowDepositSchedule = () => setShowSchedule(true);

  //Static values which should be replaced by {props}
  const highlights = [
    {
      slide: [
        {
          headertitle: "Last Deposit",
          desc: (
            <Row>
              <Col className="mt-5">
                Date
                <br />
                <span className="fw-bold ms-10">01.02.2023</span>
              </Col>
              <Col className="mt-5">
                Amount
                <br />
                <span className="fw-bold ms-10">$5,839.40</span>
              </Col>
            </Row>
          ),
        },
        {
          headertitle: "Lender",
          desc: (
            <Row>
              <Col className="mt-15 text-center fw-bold">
                First Heritage Mortgage
              </Col>
            </Row>
          ),
        },
        {
          headertitle: "Title Company",
          desc: (
            <Row>
              <Col className="mt-15 text-center fw-bold">First Excel Title</Col>
            </Row>
          ),
        },
      ],
    },
    {
      slide: [
        {
          headertitle: "Title Company",
          desc: (
            <Row>
              <Col className="mt-15 text-center fw-bold">First Excel Title</Col>
            </Row>
          ),
        },
      ],
    },
  ];
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Deposit Tracker - Home Resources | My Stanley Martin</title>
      </Helmet>
      <Container className="px-30 px-md-50" fluid>
        <Container>
          {/* Deposit Stats */}
          <Row>
            <Col sm={12} md={7} lg={6} className="px-0">
              <h3 className="mb-30">Deposit Tracker</h3>
            </Col>
          </Row>

          <Row xs={1} md={2} className="px-0 gap-40">
            <Col xs={12} md={4} style={{ maxWidth: 325 }}>
              <Row className="bg-light-blue pt-25 pb-15 border border-1 border-secondary-10 rounded rounded-4 text-center">
                <Col xs={3} md={3} className="align-self-top px-25">
                  <span
                    className="bg-primary shadow shadow-sm text-white border border-1 border-light rounded-circle fs-30"
                    style={{
                      width: 50,
                      height: 50,
                      padding: "3px 12px 13px",
                    }}
                  >
                    <FaDollarSign />
                  </span>
                </Col>
                <Col xs={9} md={8} className="align-self-center text-start">
                  <ListGroup>
                    <ListGroup.Item className="text-nowrap border-0 p-0 bg-transparent text-dark fw-bold">
                      Total Deposited Amount
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0 fs-25 fw-bold text-primary bg-transparent">
                      $9,839.40
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0 bg-transparent">
                      <Button
                        type="button"
                        variant="link"
                        size="sm"
                        onClick={handleShowDepositRecords}
                        className="fw-bolder fs-14 px-0 text-uppercase text-decoration-underline text-primary link-secondary"
                      >
                        View all deposits
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              md={4}
              className="ms-n30 ms-md-n0"
              style={{ maxWidth: 395 }}
            >
              <Row className="bg-light-blue mx-20 pt-25 pb-15 border border-1 border-secondary-10 rounded rounded-4">
                <Col xs={3} md={3} className="align-self-top px-25">
                  <span
                    className="bg-secondary shadow shadow-sm text-white border border-1 border-light rounded-circle fs-30"
                    style={{
                      width: 50,
                      height: 50,
                      padding: "3px 12px 13px",
                    }}
                  >
                    <FaFileInvoiceDollar />
                  </span>
                </Col>

                <Col xs={9} md={8} className="align-self-center">
                  <ListGroup>
                    <ListGroup.Item className="text-nowrap border-0 p-0 bg-transparent text-dark fw-bold">
                      Deposit Amount Remaining
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0 fs-25 fw-bold text-primary bg-transparent">
                      $37,560.60
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0 p-0 bg-transparent">
                      <Button
                        type="button"
                        variant="link"
                        size="sm"
                        onClick={handleShowDepositSchedule}
                        className="fw-bolder fs-14 px-0 text-uppercase text-decoration-underline text-start text-nowrap text-primary link-secondary"
                      >
                        View Deposit Schedule
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Col>
          </Row>

          <Col xs={12} className="ms-0 ms-lg-n140 my-30 position-relative z-1">
            <Slider
              props={highlights}
              recordDetails={""}
              showTimeStamp={false}
              showImg={false}
              showText={true}
              fade={false}
              slide
              showIndicators={false}
              showControls={true}
              rowSm={1}
              rowMd={4}
              rowLg={4}
              bodyPadding={"0"}
              rowPosition="center"
              buttonText="Read More"
              showCtaButton={false}
              showCardHeader
              cardHeaderTitle=""
              showBackground
              buttonClassName="bg-white p-0 text-primary fw-bold border border-0 border-bottom border-1 border-primary rounded-0"
            />
          </Col>

          {/* Deposit Calculator */}
          <Row>
            <Col xs={12} className="px-0 me-auto" style={{ maxWidth: 960 }}>
              <h3 className="mb-30">Deposit Calculator</h3>
              <Col className="bg-light border bobrder-1 border-secondary-10 p-30 rounded-4 shadow shadow-sm">
                <UserDepositCalculator />
              </Col>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* All Deposits */}
      <Modal
        show={showDepositRecords}
        onHide={handleCloseDepositRecords}
        centered
      >
        <Modal.Body className="shadow-lg p-0 bg-primary rounded-top m-auto w-100">
          <Modal.Header className="text-white p-20">
            <Col className="justify-content-start align-self-center" />
            <Col className="m-auto text-center fw-bold align-self-center d-flex">
              <span className="fs-25 text-nowrap">Deposit Records</span>
            </Col>
            <Col className="justify-content-end text-end">
              <FaWindowClose
                className="cursor-pointer"
                onClick={handleCloseDepositRecords}
              />
            </Col>
          </Modal.Header>
          <Col className="bg-light p-20 pb-0 rounded-0 rounded-bottom rounded-lg">
            <Table className="rounded rounded-4 border" responsive>
              <thead className="bg-secondary-10 fs-16 text-center">
                <tr>
                  <th>#</th>
                  <th colSpan={1}>Date</th>
                  <th>Type</th>
                  <th>Total Deposit</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr className="border-0 border-light">
                  <td className="d-block py-15">2</td>
                  <td valign="middle">06.27.22</td>
                  <td valign="middle">Check #901</td>
                  <td valign="middle">$9,839.40</td>
                </tr>
                <tr className="border-0 border-white">
                  <td className="d-block py-15">1</td>
                  <td valign="middle">06.27.22</td>
                  <td valign="middle">EMD</td>
                  <td valign="middle">$7,000.00</td>
                </tr>
                <tr className="border-0 border-white">
                  <td className="d-block py-15">25</td>
                  <td valign="middle"></td>
                  <td valign="middle"></td>
                  <td valign="middle">$16,456.50</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Modal.Body>
      </Modal>

      {/* Deposit Schedule */}
      <Modal
        show={showDepositSchedule}
        onHide={handleCloseDepositSchedule}
        centered
      >
        <Modal.Body className="shadow-lg p-0 bg-primary rounded-top m-auto w-100">
          <Modal.Header className="text-white p-20">
            <Col className="justify-content-start align-self-center" />
            <Col className="m-auto text-center fw-bold align-self-center d-flex">
              <span className="fs-25 text-nowrap">Deposit Schedule</span>
            </Col>
            <Col className="justify-content-end text-end">
              <FaWindowClose
                className="cursor-pointer"
                onClick={handleCloseDepositSchedule}
              />
            </Col>
          </Modal.Header>
          <Col className="bg-light p-20 pb-0 rounded-0 rounded-bottom rounded-lg">
            <Table className="rounded rounded-4 border" responsive>
              <thead className="bg-secondary-10 fs-16 text-center">
                <tr>
                  <th>#</th>
                  <th colSpan={1}>Date</th>
                  <th>Total Deposit</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr className="border-0 border-light">
                  <td className="d-block py-15">1</td>
                  <td valign="middle">06.27.22</td>
                  <td valign="middle">$9,839.40</td>
                </tr>
                <tr className="border-0 border-white">
                  <td className="d-block py-15">2</td>
                  <td valign="middle">06.27.22</td>
                  <td valign="middle">$7,000.00</td>
                </tr>
                <tr className="border-0 border-white">
                  <td className="d-block py-15">3</td>
                  <td valign="middle"></td>
                  <td valign="middle">$16,456.50</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Modal.Body>
      </Modal>
    </Page>
  );
}
