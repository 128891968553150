import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import UserFileDocuments from "../components/Account/UserFileDocuments";
// import GreenLivingBanner from "../components/Banner";
import { HomeDetailCard } from "../components/Card";
import PriceBreakDownCard from "../components/Card/PriceBreakDownCard";
import Page from "../components/Layout/Page";
import { homeResourceApi } from "../lib/homeApi";

export default function HomeResources() {
  const [userHomeDetails, setUserHomeDetails] = useState(false);
  const [userMemoryBookPhotos, setUserMemoryBookPhotos] = useState(false);
  const [priceBreakdown, setPriceBreakdown] = useState(false);
  const [homeResourceDocument, setHomeResourceDocument] = useState(false);

  let financialInstanceID = sessionStorage.getItem("userFinancialId");
  useEffect(() => {
    homeResourceApi(financialInstanceID)
      .then((res) => {
        setUserHomeDetails(res.home[0]);
        setUserMemoryBookPhotos(res.memoryBookPhotos);
        setPriceBreakdown(res.priceBreakdown[0]);
        setHomeResourceDocument(res.homeResourceDocuments);
      })
      .catch((e) => {});
  }, [financialInstanceID]);

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home Resources | My Stanley Martin</title>
      </Helmet>
      <Container className="px-30 px-md-50 pb-50" fluid>
        <Container className="m-0 p-0 max-view-width">
          <Row>
            <Col sm={12} md={6}>
              <HomeDetailCard
                cardTitle="Home Details"
                props={userHomeDetails}
                userMemoryBookPhotos={userMemoryBookPhotos}
                showCommunityDetails={false}
                showAddress
                showMoreDetails
              />
            </Col>
            <Col xs={12} md={6} style={{ minWidth: "250px" }}>
              <PriceBreakDownCard props={priceBreakdown} />
            </Col>
            {/* {financialInstanceID !== "" && <GreenLivingBanner />} */}
            {Object.keys(homeResourceDocument).length !== 0 && (
              <UserFileDocuments doc={homeResourceDocument} />
            )}
          </Row>
        </Container>
      </Container>
    </Page>
  );
}
