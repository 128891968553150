import React from "react";
import { Col, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NaveMenu from "../Menu/NaveMenu";
import NavMenuClassic from "../Menu/NavMenuClassic";
import NaveMenuInvestor from "../Menu/NaveMenuInvestor";

export default function SidebarCanvas() {
  const { pathname } = useLocation();
  const [root, cat] = pathname.split("/");
  const currentCat = `${root}/${cat}`;
  
  let projectGroupId = sessionStorage.getItem("projectGroupID");
  let accountType = sessionStorage.getItem("accountType");

  return (
    <Nav
      id="sidebarMenu"
      variant="dark"
      defaultActiveKey={`${currentCat}`}
      className={`px-15 pt-160 sidebar bg-primary d-none d-md-block z-2`}
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        left: "0",
        zindex: "100",
        maxWidth: "240px",
        width: "240px",
      }}
    >
      <Col
        className="position-sticky sidebar-sticky m-0 fw-bold"
        style={{
          height: "calc(100vh - 48px)",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {accountType === "1" ? (
            <NaveMenu showIcons={false} projectGroupId={projectGroupId} />
        ) : (
         accountType === "2" ? (
             <NaveMenuInvestor/>
        ) : (
            <NavMenuClassic showIcons={false} />
        ))}
      </Col>
    </Nav>
  );
}
