export async function homeDetailsApi(userFinancialId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userFinancialId }),
  };
    const response = await fetch("mysmh/Home/Home", requestOptions);
  return await response.json();
}

export async function homeResourceApi(userFinancialId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userFinancialId }),
    };
    const response = await fetch("mysmh/Home/HomeResource", requestOptions);
    return await response.json();
}

export async function greenLivingApi(userFinancialId) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userFinancialId }),
    };
    const response = await fetch("mysmh/Home/GreenLiving", requestOptions);
    return await response.json();
}
