export async function investorHomeDetailsApi(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
    }),
  };
  const response = await fetch(
    "mysmh/Investor/Investor",
    requestOptions
  );
  return await response.json();
}
