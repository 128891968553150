import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import useNameAbr from "../../hooks/useNameAbr";

export default function NavBarAvatar({
  width,
  height,
  fontSize,
  paddingTop,
  noMarginBottom,
}) {
  let nameAbr = useNameAbr();
  const [userProfilePhoto, setUserProfilePhoto] = useState(
    sessionStorage.getItem("profileImageUrl")
  );

  useEffect(() => {
    // setup a function to be called every second
    const intervalId = setInterval(() => {
      const newPhoto = sessionStorage.getItem("profileImageUrl");
      if (newPhoto !== userProfilePhoto) {
        setUserProfilePhoto(newPhoto);
      }
    }, 1000);

    // clean up function
    return () => clearInterval(intervalId);
  }, [userProfilePhoto]);

  return (
    <>
      {userProfilePhoto !== "" ? (
        <Col style={{ marginTop: 3.5 }}>
          <Image
            className={`rounded-circle m-auto ${noMarginBottom ?? "mb-n50"}`}
            src={userProfilePhoto}
            width={width}
            height={height}
          />
        </Col>
      ) : (
        <Col
          id="profile"
          className={`text-primary d-flex justify-content-center bg-tertiary fw-light rounded-circle text-center m-auto px-0 ${
            noMarginBottom ?? "mb-n50"
          }`}
          style={{
            width: width,
            height: height,
            fontSize: fontSize,
            paddingTop: paddingTop,
            alignContent: "center",
            textAlign: "center",
            borderRadius: "100%",
          }}
        >
          {nameAbr}
        </Col>
      )}
    </>
  );
}
