import React, { createContext, useState } from "react";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [recID, setRecID] = useState(
    sessionStorage.getItem("recID")
  );
  const [projectGroupID, setProjectGroupID] = useState(
    sessionStorage.getItem("projectGroupID")
  );
  const [email, setEmail] = useState(
    sessionStorage.getItem("email")
  );
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [lastName, setLastName] = useState(
    sessionStorage.getItem("lastName")
  ); 
  const [userFinancialId, setUserFinancialId] = useState(
    sessionStorage.getItem("userFinancialId")
  );
  const [isValid, setIsValid] = useState(
    sessionStorage.getItem("isValid")
  );
  const [profileImageUrl, setProfileImageUrl] = useState(
    sessionStorage.getItem("profileImageUrl")
  );
  const [accountType, setaccountType] = useState(
    sessionStorage.getItem("accountType")
  );

  const updateSession = (
    recID,
    projectGroupID,
    email,
    firstName,
    lastName,
    userFinancialId,
    isValid,
    profileImageUrl,
    accountType
  ) => {
    setRecID(recID);
    setProjectGroupID(projectGroupID);
    setEmail(email);
    setFirstName(firstName);
    setLastName(lastName);
    setUserFinancialId(userFinancialId);
    setIsValid(isValid);
    setProfileImageUrl(profileImageUrl)
    setaccountType(accountType);
    sessionStorage.setItem("recID", recID || "");
    sessionStorage.setItem("projectGroupID", projectGroupID || "");
    sessionStorage.setItem("email", email || "");
    sessionStorage.setItem("firstName", firstName || "");
    sessionStorage.setItem("lastName", lastName || "");
    sessionStorage.setItem("userFinancialId", userFinancialId || "");
    sessionStorage.setItem("isValid", isValid || false);
    sessionStorage.setItem("profileImageUrl", profileImageUrl || "");
    sessionStorage.setItem("accountType", accountType || "");
  };

  return (
    <SessionContext.Provider
      value={{
        recID,
        projectGroupID,
        email,
        firstName,
        lastName,
        userFinancialId,
        isValid,
        profileImageUrl,
        accountType,
        updateSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
