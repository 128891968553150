export async function validEmailApi(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  const response = await fetch("mysmh/Account/ValidEmail", requestOptions);
  return await response.json();
}

export async function generateTempPasswordApi(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  const response = await fetch(
    "mysmh/Account/GenerateTempPassword",
    requestOptions
  );
  return await response.json();
}

export async function authenticationApi(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  const response = await fetch("mysmh/Account/Authentication", requestOptions);
  return await response.json();
}

export async function passwordResetApi(email, passwordtemp, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, passwordtemp, password }),
  };
  const response = await fetch("mysmh/Account/PasswordReset", requestOptions);
  return await response.json();
}

export async function forgotPasswordApi(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };
  const response = await fetch("mysmh/Account/ForgotPassword", requestOptions);
  return await response.json();
}

export async function signUpApi(
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    //unitApt,
    city,
    state,
    zipCode,
    regionalLocation) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
            address,
            //unitApt,
            city,
            state,
            zipCode,
            regionalLocation }),
    };
    const response = await fetch("mysmh/Account/SignUp", requestOptions);
    return await response.json();
}

export async function getUserAccountDetails(recID) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            recID
        }),
    };
    const response = await fetch("mysmh/Account/GetUserAccountDetails", requestOptions);
    return await response.json();
}

export async function updateProfileApi(
    recID,
    firstName,
    lastName,
    email,
    phoneNumber,
    address,
    unitApt,
    city,
    state,
    zipCode,
    isEmailCommunication,
    isTextCommunication) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            recID,
            firstName,
            lastName,
            email,
            phoneNumber,
            address,
            unitApt,
            city,
            state,
            zipCode,
            isEmailCommunication,
            isTextCommunication
        }),
    };
    const response = await fetch("mysmh/Account/UpdateProfile", requestOptions);
    return await response.json();
}
export async function updateProfilePhotoApi(formData) {
  const requestOptions = {
    method: "POST",
    body: formData,
  };
    const response = await fetch("mysmh/Account/UploadProfilePicture", requestOptions);
    return await response.json();
}


export async function updatePasswordApi(recID,
    oldPassword,
    password
) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            recID,
            oldPassword,
            password
        }),
    };
    const response = await fetch("mysmh/Account/UpdatePassword", requestOptions);
    return await response.json();
}


export async function getRegionalLocation() {
  const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
  };
  const response = await fetch("mysmh/Account/GetRegionalLocation", requestOptions);
  return await response.json();
}