import React from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NaveMenu from "../Menu/NaveMenu";
import NavMenuClassic from "../Menu/NavMenuClassic";
import NavMenuLogin from "../Menu/NavMenuLogin";
import NaveMenuInvestor from "../Menu/NaveMenuInvestor";

export default function NavBarOffCanvas({ logoSrc, name, ...props }) {
  const { pathname } = useLocation();
  const [root, cat] = pathname.split("/");
  const currentCat = `${root}/${cat}`;
  let projectGroupId = sessionStorage.getItem("projectGroupID");
  let accountType = sessionStorage.getItem("accountType");

  return (
    <Offcanvas
      {...props}
      placement="end"
      className="text-bg-primary fw-bold mt-65"
      show={props.show}
      onHide={props.onHide}
      style={{
        width: 220,
      }}
    >
      <Offcanvas.Header closeButton closeVariant="white" />
      <Offcanvas.Body>
        <Nav id="sidebarMenu" variant="dark" defaultActiveKey={`${currentCat}`}>
          {cat === "" ? (
            <NavMenuLogin />
          ) : (
            <>
              {accountType === "1" ? (
                  <NaveMenu projectGroupId={projectGroupId} showIcons />
              ) : (
                accountType === "2" ? (
                    <NaveMenuInvestor />
                ) : (
                    <NavMenuClassic showIcons />
              ))}
            </>
          )}
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
