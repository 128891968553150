import React from "react";
import { Nav } from "react-bootstrap";

export default function NaveMenuInvestor({ showIcons }) {
  return (
    <>
      <Nav.Link href="/my-homes" className="mb-40 py-0">
        My Homes
      </Nav.Link>
      <Nav.Link href="/contact-us" className="mb-40 py-0">
        Contact Us
      </Nav.Link>
    </>
  );
}
