import { Button, Col, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { FaClipboardCheck, FaWindowClose } from "react-icons/fa";
import RegisterForm from "./RegisterForm";
import RegisterConfirmModal from "./RegisterConfirmModal";

export default function RegisterModal({
  show,
  onHide,
  parentmodalprops,
  useremail,
  locations,
}) {
  const [showLoginConfirmModal, setShowLoginConfirmModal] = useState(false);
  const handleRegisterFormSubmit = () => {
    setShowLoginConfirmModal(true);
  };
  return (
    <>
      <Modal
        id="registerModal"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
      >
        <Modal.Body
          style={{ maxWidth: "565px" }}
          className="shadow-lg p-0 bg-primary rounded-top"
        >
          <Modal.Header className="text-white pb-0">
            <Col className="justify-content-start align-self-center"></Col>
            <Col className="me-auto fw-bold align-self-center">
              <Col className="w-100 text-nowrap">
                <Col className="pe-10 d-flex">
                  <span
                    className="me-10 bg-secondary rounded-circle"
                    style={{
                      marginTop: 0,
                      paddingTop: 0,
                      paddingRight: 8,
                      paddingLeft: 8,
                      paddingBottom: 5,
                      height: 30,
                      width: 30,
                    }}
                  >
                    <FaClipboardCheck size={13} />
                  </span>
                  <span style={{ paddingTop: 3 }}>Create an Account</span>
                </Col>
              </Col>
            </Col>

            <Col className="justify-content-end text-end">
              <FaWindowClose className="cursor-pointer" onClick={onHide} />
            </Col>
          </Modal.Header>
          <Col
            xs={12}
            className="text-white text-center pt-20 pb-25 m-auto px-20"
          >
            <p className="mb-0">
              A <span className="font-secondary fst-italic">my</span>StanleyMartin account will allow you to create warranty requests
              and have access to homeowner resources. If you have any issues with
              your account, please contact:{" "}
            <Button
              alt="Contact Portal Support"
              variant="link"
              className="mt-n5 p-0 text-white text-decoration-underline"
              href="mailto:portalsupport@stanleymartin.com"
              rel="noreferrer"
              type="button"
            >
              portalsupport@stanleymartin.com
            </Button>
            </p>
          </Col>
          <Col className="bg-light px-30 pt-20 pb-0 rounded-0 rounded-bottom rounded-lg">
            <RegisterForm
              closeModal={onHide}
              onFormSubmit={handleRegisterFormSubmit}
              registerformprops={parentmodalprops.onHide}
              useremail={useremail}
              locations={locations}
            />
          </Col>
        </Modal.Body>
      </Modal>
      <RegisterConfirmModal
        show={showLoginConfirmModal}
        onHide={() => setShowLoginConfirmModal(false)}
        registerformprops={parentmodalprops.onHide}
        useremail={useremail}
      />
    </>
  );
}
