import React from "react";
import { ListGroup } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

//pass {props} to map data below in return
export default function PriceBreakDownCard({ props }) {
  return (
    <>
      <h3 className="mb-0">Price Breakdown</h3>
      <p className="fs-18">
        Below is the breakdown for the price of your home.
      </p>
      <ListGroup as="ul">
        <ListGroup.Item as="li" className="text-bg-primary py-20">
          <span className="text-accent fw-bold me-5">Price:</span>
          {props.currentPrice}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">Base Price:</span>
          {props.basePrice}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">Homesite Premium:</span>
          {props.LotPremium}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">Discounts:</span>
          {props.salesDiscount}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">Total Selections Price:</span>
          {props.option}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">
            <FaInfoCircle
              className="text-accent mt-n5 me-5 me-5"
              title={props.structuralOptionsList}
            />
            Structural Selections:
          </span>
          {props.structuralOptions}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">
            <FaInfoCircle
              className="text-accent mt-n5 me-5"
              title={props.designOptionList}
            />
            Design Selections:
          </span>
          {props.designOptions}
        </ListGroup.Item>
        <ListGroup.Item as="li" className="py-10">
          <span className="text-primary fw-bold me-5">
            <FaInfoCircle
              className="text-accent mt-n5 me-5"
              title={props.customOptionList}
            />
            Additional Selections:
          </span>
          {props.customOptions}
        </ListGroup.Item>
      </ListGroup>
      {/* <Col className="d-flex justify-content-center align-self-center">
        <Button
          variant="primary"
          size="sm"
          href="home-resources/deposit-tracker"
          className="my-40"
        >
          VIEW YOUR DEPOSIT TRACKER
        </Button>
      </Col> */}
    </>
  );
}
