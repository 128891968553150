import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AccountProfileForm from "../components/Account/AccountProfileForm";
import Page from "../components/Layout/Page";

export default function Account() {
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Account | My Stanley Martin</title>
      </Helmet>
      <Container className="px-30 px-md-50" fluid>
        <Container className="m-0 p-0">
          <AccountProfileForm />
        </Container>
      </Container>
    </Page>
  );
}
