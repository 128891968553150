import React, { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import SiteName from "../SiteName";
import RegisterModal from "./RegisterModal";
import { getRegionalLocation } from "../../lib/mysmhApi";

export default function LoginModal({
  show,
  onHide,
  isEmailFound,
  useremail,
  ...props
}) {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const handleFormData = async () => {
      setShowRegisterModal(true);
      onHide(); 
    const locations = await getRegionalLocation();
    setLocations(locations)
  }

  const handleRegisterSubmit = async ()  =>  {
    props.onHide();
  };
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Body className="text-bg-primary text-center rounded-4 pb-45 shadow-lg pt-0 fs-20">
          <Modal.Header className="text-white">
            <Col className="justify-content-start align-self-center" />
            <Col className="m-auto text-center fw-bold align-self-center d-flex" />
            <Col className="justify-content-end text-end " />
          </Modal.Header>
          <Row>
            <Col xs={12}>
              <SiteName />
            </Col>
            {!isEmailFound && (
              <Col xs={12}>
                <p className="text-red-email-not-found fw-bold mt-20 d-block pt-0">
                  This email is not associated with any account.
                </p>
                <p className="px-25 fw-light">
                  If you feel that this is a mistake, please{" "}
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 text-white m-0 text-decoration-underline mt-n5 fs-18"
                    onClick={onHide}
                  >
                    click here
                  </Button>{" "}
                  to re-enter your email or click below to create an account.
                </p>
              </Col>
            )}

            {isEmailFound && (
              <Col xs={12}>
                <p className="px-25 mb-0 fs-18">
                  {useremail} <br /> is not associated with any account.
                </p>
              </Col>
            )}
            <Col xs={12}>
              <Button
                type="button"
                variant="success"
                className="mt-30"
                size="md"
                onClick={handleFormData}
              >
                Create Account
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <RegisterModal
        show={showRegisterModal}
        onHide={() => setShowRegisterModal(false)}
        parentmodalprops={props}
        onSubmit={handleRegisterSubmit}
        useremail={useremail}
        locations={locations}
      />
    </>
  );
}
