import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import TeamContactCard from "../components/Card/TeamContactCard";
import Page from "../components/Layout/Page";
import { getContactsApi } from "../lib/contactApi";

export default function Contacts() {
  const [contacts, setContacts] = useState(false);
  const recID = sessionStorage.getItem("recID");
  const email = sessionStorage.getItem("accountType") === "2" ? sessionStorage.getItem("email") : null;
  useEffect(() => {
    getContactsApi(recID, email)
      .then((res) => {
        setContacts(res);
      })
      .catch((e) => {});
  }, [recID, email]);
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | My Stanley Martin</title>
      </Helmet>
      <Container className="px-30 px-md-50 pb-50" fluid>
        <Container className="m-0 p-0 max-view-width">
          <h3 className="mb-10">Your Team Contacts</h3>
          <Col xs={12} className="mb-25">
            If you have any issues with your account, please contact:{" "}
            <Button
              alt="Contact Portal Support"
              variant="link"
              className="p-0 text-primary mt-n5"
              href="mailto:portalsupport@stanleymartin.com"
              rel="noreferrer"
              type="button"
            >
              portalsupport@stanleymartin.com
            </Button>
          </Col>
          <Row xs={1} md={2} lg={3} className="g-30">
            {contacts &&
              contacts.map((contact, index) => (
                <Col xs={12} md={2} key={index}>
                  <TeamContactCard
                    email={contact.email}
                    imgsrc={contact.imagesrc}
                    name={contact.name}
                    title={contact.title}
                    phone={contact.phone}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </Container>
    </Page>
  );
}
