import React, { useContext } from "react";
import { Button, Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../SessionProvider";
import NavBarAvatar from "./NavBarAvatar";

export default function NavBarAccount() {
  let firstName = sessionStorage.getItem("firstName");
  const navigate = useNavigate();
  const { updateSession } = useContext(SessionContext);
  const handleSignOut = () => {
    sessionStorage.clear();
    updateSession("", false);
    navigate("/");
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as="div" id="profile" variant="transparent">
        <NavBarAvatar
          width="40px"
          height="40px"
          fontSize="15px"
          paddingTop="8px"
          noMarginBottom
        />
      </Dropdown.Toggle>
      <Dropdown.Menu
        as="div"
        align={"left"}
        className="bg-transparent p-0 m-0 fs-14 rounded-4"
        style={{ left: "-200px" }}
      >
        <Col className="p-0">
          <ListGroup as="ul" className="p-0 m-0 rounded-4">
            <ListGroup.Item
              as="li"
              className="m-0 px-50 border-0 text-bg-primary"
            >
              <Row>
                <Col xs={12} className="align-self-center text-center pt-20">
                  <NavBarAvatar
                    width="50px"
                    height="50px"
                    paddingTop="13px"
                    fontSize="15px"
                    noMarginBottom
                  />
                  <Col className="mt-10 mb-15 fw-bold text-nowrap fs-18">
                    {firstName}
                  </Col>
                  <Col className="fw-bold">
                    <Button
                      variant="outline-white"
                      className="border border-white mb-15 py-10 px-20"
                      href="/my-account"
                    >
                      My Profile
                    </Button>
                  </Col>
                  <Col className="fw-bold">
                    <Button
                      variant="outline-white"
                      className="border border-white mb-15 py-10 px-20"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </Button>
                  </Col>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Dropdown.Menu>
    </Dropdown>
  );
}
