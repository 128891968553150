import {
  Home,
  Login,
  Neighborhood,
  Contacts,
  HomeResources,
  Warranty,
  WarrantyClaims,
  DocumentPreview,
  DepositeTracker,
  // GreenLiving,
  Account,
  Investor
} from "./Pages";

const AppRoutes = [
  {
    index: true,
    path: "/*",
    element: <Login />,
  },
  {
    path: "/my-account",
    element: <Account />,
  },
  {
    path: "/contact-us",
    element: <Contacts />,
  },
  {
    path: "/warranty",
    element: <Warranty />,
  },
  {
    path: "/warranty/warranty-claims",
    element: <WarrantyClaims />,
  },
];

const AppPageRoutes = {
    "1": [
        {
            path: "/my-home",
            element: <Home />,
        },
        {
            path: "/neighborhood",
            element: <Neighborhood />,
        },
        {
            path: "/home-resources",
            element: <HomeResources />,
        },
        {
            path: "/home-resources/document/preview",
            element: <DocumentPreview />,
        },
        {
            path: "/home-resources/deposit-tracker",
            element: <DepositeTracker />,
        },
        // {
        //   path: "/home-resources/green-living",
        //   element: <GreenLiving />,
        // },
    ],
    "2": [
        {
            path: "/my-homes",
            element: <Investor />,
        },
    ],
};

export { AppRoutes, AppPageRoutes };
