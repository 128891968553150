import React from "react";
import { Col } from "react-bootstrap";

export default function Footer({pathname}) {
  return (
    <footer className="ps-0 ps-md-150 py-20 text-bg-primary text-center position-relative mt-auto w-100">
      <Col sm={12}>
        ©{new Date().getFullYear()} Stanley Martin Homes, LLC. All rights. Equal
        Housing Opportunity reserved.
      </Col>
      {pathname === "/my-home" && (
        <Col sm={10} className="pt-5 m-auto">
          <p className="mb-0 d-none">
            *Future dates are estimates and may change. Wait for official
            written notice as outlined in the New Home Sales Contract before
            taking any action such as scheduling a move or locking an interest
            rate.
          </p>
        </Col>
      )}
    </footer>
  );
}
