import React, { useContext, useRef, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SessionContext } from "../../SessionProvider";
import SiteName from "../SiteName";
import LoginForm from "./LoginForm";
import LoginModal from "./LoginModal";
import TempPasswordModal from "./TempPasswordModal";
import ForgotPasswordConfirmModal from "./ForgotPasswordConfirmModal";
import {
  authenticationApi,
  generateTempPasswordApi,
  validEmailApi,
} from "../../lib/mysmhApi";

export default function LoginUser() {
  //Use session
  const { updateSession } = useContext(SessionContext);

  //Redirect
  const navigate = useNavigate();

  //Form
  const [validated, setValidated] = useState(false);
  const [isEmailFound, setEmailFound] = useState(false);

  //Show Password
  // eslint-disable-next-line no-unused-vars
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  //Show Temp Password
  // eslint-disable-next-line no-unused-vars
  const [showTempPasswordInput, setShowTempPasswordInput] = useState(false);

  //Claimer
  // eslint-disable-next-line no-unused-vars
  const [showClaimer, setShowClaimer] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showHelpText, setShowHelpText] = useState(true);

  //Modals
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [showTempPasswordModal, setShowTempPasswordModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const handleForgotPasswordModalShow = async () => {
    await generateTempPasswordApi(userSubmitEmail);
    setShowForgotPasswordModal(true);
  };

  //Error
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [userSubmitEmail, setUserSubmitEmail] = useState("");
  const handleUserEmail = (event) => {
    const userEmailWithoutSpaces = event.target.value.replace(/\s+/g, "");
    setUserSubmitEmail(userEmailWithoutSpaces);
    setShowErrorMessage(false);
  };

  const [userPassword, setUserPassword] = useState("");
  const handleUserPassword = (event) => {
    setUserPassword(event.target.value.trim());
  };

  const formRef = useRef(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const password = form.elements.userPassword?.value;
    const email = form.elements.userEmail.value;
    const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    if (email.length === 0) {
      setShowErrorMessage(true);
      setErrorMessage("Please enter an email address");
    } else if (!emailFormat.test(email)) {
      setShowErrorMessage(true);
      setErrorMessage("Invalid email format");
    } else {
      setShowErrorMessage(false);
      setValidated(true);
      //Flags from fetched account
      const account = await validEmailApi(userSubmitEmail);
      const email = account.email;
      const isUserExist = account.isUserExist;
      const isUserFirstLogin = account.isUserFirstLogin;

      if (isUserExist === "True") {
        setShowPasswordInput(true);
        setEmailFound(true);
      } else {
        setShowCreateAccountModal(true);
        setShowPasswordInput(false);
        setEmailFound(false);
      }
      if (
        isUserExist === "True" &&
        isUserFirstLogin === "False" &&
        email !== undefined &&
        password !== undefined
      ) {
        //Authenticate User
        const authUser = await authenticationApi(email, password); //This api is called with signup flow as well during password reset
        const isAuthenticated = authUser.isAuthenticated;
        const accountType = authUser.accountType;

        if (isAuthenticated === "True") {
          const userProps = {
            email: authUser.email,
            firstName: authUser.firstName,
            lastName: authUser.lastName,
            userFinancialId: accountType === "2" ? null : authUser.userFinancialId,
            isValid: authUser.isAuthenticated,
            recID: authUser.recID,
            profileImageUrl: authUser.profileImageUrl,
            accountType: authUser.accountType,
          };
          updateSession(
            authUser.recID,
            authUser.projectGroupID,
            authUser.email,
            authUser.firstName,
            authUser.lastName,
            accountType === "2" ? null : authUser.userFinancialId,
            authUser.isAuthenticated,
            authUser.profileImageUrl,
            authUser.accountType
          );

          //If object doesnt exist on load it would be null, when a session variable is set string or empty string "" will be not null type
            if (accountType === "1") {
                navigate("/my-home", { state: userProps });
            } else if (accountType === "2") {
                navigate("/my-homes", { state: userProps });
            } else {
                navigate("/warranty", { state: userProps });
            }
        } else {
          if (userPassword === "") {
            setShowErrorMessage(true);
            setErrorMessage("Enter Password");
          } else if (authUser !== true) {
            setShowErrorMessage(true);
            setErrorMessage("Wrong Password");
          }
        }
      }

      //Is first time login user show temp modal
      if (isUserExist === "True" && isUserFirstLogin === "True") {
        setValidated(true);
        setEmailFound(true);
        setShowPasswordInput(false);
        setShowTempPasswordModal(true);
        setShowErrorMessage(false);
        setErrorMessage("");
      }
    }
  };
  return (
    <Col
      sm={12}
      className="bg-primary rounded-5 text-white pt-25 pb-30 px-0 m-auto"
      style={{
        maxWidth: "560px",
      }}
    >
      <Col sm={12} className="px-30 px-md-85">
        <SiteName />
        {showHelpText && (
          <p className="fs-18 text-center">
            Submit the email address that is associated with your Stanley Martin purchase.
          </p>
        )}
        <LoginForm
          formRef={formRef}
          validated={validated}
          handleSubmit={handleSubmit}
          userEmail={userSubmitEmail}
          handleUserEmail={handleUserEmail}
          userPassword={userPassword}
          showPasswordInput={showPasswordInput}
          handleUserPassword={handleUserPassword}
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
        />
        {showClaimer && (
          <p className="text-center fs-13 pt-25 d-none d-md-block">
            If you are experiencing flooding, potential safety or fire hazards
            or no heat during winter, please call <b>888.433.6442</b>. If you
            suspect a gas leak, contact your gas company immediately.
            {/* <b>
              Warranty service is completed Monday - Friday from 8:00AM -
              4:00PM.{" "}
            </b>{" "}
            After-hours emergency calls should be limited to true emergencies
            such as flooding, potential safety or fire hazards and no heat
            during winter. If you suspect a gas leak, you should immediately
            contact the gas company before reporting it to our offices at{" "}
            <b>888.433.6442</b>.*/}
          </p>
        )}

        <Col className="text-center mt-25">
          <Button
            type="button"
            variant="white"
            className="fw-bold p-0 bg-transparent"
            onClick={handleForgotPasswordModalShow}
            disabled={!userSubmitEmail || !isEmailFound}
          >
            Forgot Password?
          </Button>
        </Col>
      </Col>
      <LoginModal
        show={showCreateAccountModal}
        onHide={() => setShowCreateAccountModal(false)}
        useremail={userSubmitEmail}
        isEmailFound={isEmailFound}
      />

      <TempPasswordModal
        show={showTempPasswordModal}
        onHide={() => setShowTempPasswordModal(false)}
        showpasswordinput={() => setShowTempPasswordInput(false)}
        useremail={userSubmitEmail}
      />

      {userSubmitEmail && (
        <ForgotPasswordConfirmModal
          show={showForgotPasswordModal}
          onHide={() => setShowForgotPasswordModal(false)}
          showpasswordinput={() => setShowTempPasswordInput(true)}
          showpasswordtempmodal={() => setShowTempPasswordModal(true)}
          useremail={userSubmitEmail}
        />
      )}
    </Col>
  );
}
