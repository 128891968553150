import React from "react";
import { Col, Row, Tab } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function UserFileDocuments(doc) {
  var ratifiedSalesContract = "";
  var designPackage = "";
  var selectionsChangeAmendment = "";
  if (doc.doc !== undefined && doc.doc !== false) {
    if (doc.doc["SLS-Ratified Contract"] !== undefined) {
      ratifiedSalesContract = doc.doc["SLS-Ratified Contract"];
    }

    if (doc.doc["SLS-Ratified DS Package"] !== undefined) {
      designPackage = doc.doc["SLS-Ratified DS Package"];
    }

    if (doc.doc["SLS-Ratified Change Orders"] !== undefined) {
      selectionsChangeAmendment = doc.doc["SLS-Ratified Change Orders"];
    }
  }

  return (
    <Tab.Container defaultActiveKey="first">
      <Row>
        <Col xs={12} className="mb-20">
          <h3 className="mb-0 mt-40">Home Resource Documents</h3>
        </Col>
      </Row>
      <Row className="home-docs">
        {ratifiedSalesContract !== "" ? (
            <Col xs={12} md={3} style={{maxHeight: 160}}>
              <Link
                className="btn btn-warranty border border-2 text-primary shadow-sm rounded rounded-4 border-secondary-10 w-100 fs-16 text-start px-20 py-40 mb-20"
                to="/home-resources/document/preview"
                state={{ data: ratifiedSalesContract }}
              >
                <FaFileAlt className="text-secondary mt-n5 me-5" size={25} />
                <br />
                <span className="fw-bold mt-10 d-block">New Home Sales Contract</span>
              </Link>
            </Col>
        ) : (
          <></>
        )}

        {designPackage !== "" ? (
          <Col xs={12} md={3} style={{maxHeight: 160}}>
            <Link
              className="btn btn-warranty border border-2 text-primary shadow-sm rounded rounded-4 border-secondary-10 w-100 fs-16 text-start px-20 py-40 mb-20"
              to="/home-resources/document/preview"
              state={{ data: designPackage }}
            >
              <FaFileAlt className="text-secondary mt-n5 me-5" size={25} />
              <br />
              <span className="fw-bold mt-10 d-block">Design Selections Package</span>
            </Link>
          </Col>
        ) : (
          <></>
        )}

        {selectionsChangeAmendment !== "" ? (
          <Col xs={12} md={3} style={{maxHeight: 160}}>
            <Link
              className="btn btn-warranty border border-2 text-primary shadow-sm rounded rounded-4 border-secondary-10 w-100 fs-16 text-start px-20 py-40 mb-20"
              to="/home-resources/document/preview"
              state={{ data: selectionsChangeAmendment }}
            >
              <FaFileAlt className="text-secondary mt-n5 me-5" size={25} />
              <br />
              <span className="fw-bold mt-10 d-block text-nowrap">Amendments to Contract</span>
            </Link>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Tab.Container>
  );
}
