import React from "react";
import NavBar from "../Toolbar/NavBar";
import { Helmet } from "react-helmet";

export default function Master({ children }) {
  return (
    <>
      <Helmet>
        <script src="https://64e7cb3f14db4c8f808a7a51aa832e81.js.ubembed.com" async></script>
      </Helmet>
      <NavBar />
      {children}
    </>
  );
}
