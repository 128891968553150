import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";

function WarrantyHeader({ value }) {
  return (
    <Col
      xs={1}
      className={`pt-5 pb-15 fw-bold ${
        value === "#" ? "text-start ps-15" : "text-center"
      }`}
    >
      {value}
    </Col>
  );
}
function WarrantyCol({ value }) {
  return (
    <Col xs={1} className="bg-white py-10 ps-5 text-start fw-bold">
      {value}
    </Col>
  );
}

function DateHeader({ value }) {
  return (
    <Col xs={4} className="pt-5 pb-15 fw-bold text-center">
      {value}
    </Col>
  );
}
function DateCol({ value }) {
  return (
    <Col xs={4} className="bg-white py-10 text-center">
      {value}
    </Col>
  );
}

function CategoryHeader({ value }) {
  return (
    <Col xs={4} className="pt-5 pb-15 fw-bold text-center">
      {value}
    </Col>
  );
}
function CategoryCol({ value }) {
  return (
    <Col xs={4} className="bg-white py-10 ps-5 text-center">
      {value}
    </Col>
  );
}

function EmergencyHeader({ value }) {
  return (
    <Col xs={3} className="pt-5 pb-15 fw-bold text-center">
      {value}
    </Col>
  );
}
function EmergencyCol({ value }) {
  return (
    <Col xs={3} className="bg-white py-10 text-center fw-bold">
      {value}
    </Col>
  );
}

function TableFooter({ value }) {
  return (
    <Col xs={12} className="text-start bg-light text py-10 ps-15">
      {value}
    </Col>
  );
}

export default function WarrantyRequestTable({ warrantyClaims }) {
  const claims = warrantyClaims;
  return (
    <>
      {claims.length !== 0 && (
        <Row
          xs={4}
          className="bg-light-blue px-0 me-10 pt-10 g-0 gap-0 border border-1 border-secondary-10 table-custom"
        >
          <WarrantyHeader key="1" value="#" />
          <DateHeader key="2" value="Requested Date" />
          <CategoryHeader key="3" value="Warranty Category" />
          <EmergencyHeader key="4" value="Emergency" />
          {warrantyClaims &&
            warrantyClaims.map((claim, index) => (
              <React.Fragment key={index}>
                <WarrantyCol
                  value={
                    <>
                      {claim.emergency === true && (
                        <FaExclamationCircle
                          size={15}
                          className="text-red ms-10 mt-n5"
                        />
                      )}
                      <span className="p-10">#{(index + 1).toString()}</span>
                    </>
                  }
                />
                <DateCol value={claim.requestedDateTime} />
                <CategoryCol value={claim.requestCategoryName} />
                <EmergencyCol value={claim.emergency} />
                <TableFooter
                  value={
                    <>
                      <span className="fw-bold pe-5">Comments:</span>
                      <span>
                        {claim.description.charAt(0).toUpperCase() +
                          claim.description.slice(1)}
                      </span>
                    </>
                  }
                />
              </React.Fragment>
            ))}
        </Row>
      )}
    </>
  );
}
