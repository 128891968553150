import React from "react";
import { Col } from "react-bootstrap";
import NavBar from "../Toolbar/NavBar";
import SidebarCanvas from "../Sidebar/SidebarCanvas";
import NavSiteCrumbs from "../Toolbar/NavSiteCrumbs";
import Footer from "./Footer";
import { rootImagePath } from "../../lib/Helpers";
import { useLocation} from "react-router-dom";

export default function Page({ children }) {
  //Breadcrumbs
  const { pathname } = useLocation();
  const [root, cat, subcat, tertcat] = pathname.split("/");
  const isGreenLiving = `${root}/${subcat}` === "/green-living";

  //Background styling
  const greenLivingImg = `${rootImagePath}/images/homeresources/greenliving/grass.png`;
  const greenLivingBg = {
    backgroundImage: `url(${greenLivingImg})`,
    backgroundPosition: "bottom center",
    backgroundRepeat: "repeat-x",
    backgroundSize: "contain",
  };

  return (
    <>
      <NavBar />
      <Col
        xs={12}
        className="me-auto pt-70 pt-md-120 pt-lg-120 ps-md-20 ps-md-190"
      >
        <SidebarCanvas />
        <main
          className={`p-0 ms-0 ms-md-50 ${isGreenLiving && "pb-50"}`}
          style={isGreenLiving ? greenLivingBg : {}}
        >
          <NavSiteCrumbs
            root={root}
            cat={cat}
            subcat={subcat}
            tertcat={tertcat}
          />
          {children}
        </main>
      </Col>
      <Footer pathname={pathname} />
    </>
  );
}
