import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";

export default function NaveMenu({ showIcons, projectGroupId }) {
  return (
    <>
      <Nav.Link href="/my-home" className="mb-40 py-0">
        My Home
      </Nav.Link>
      <Nav.Link href="/home-resources" className="mb-40 py-0">
        <Row>
          <Col className="d-flex justify-content-start text-nowrap">
            Home Resources
          </Col>
          {showIcons === true && (
            <Col className="d-flex justify-content-end align-self-center">
              <FaChevronRight />
            </Col>
          )}
        </Row>
      </Nav.Link>
      {projectGroupId !== "null" && (
        <Nav.Link href="/neighborhood" className="mb-40 py-0">
          Neighborhood
        </Nav.Link>
      )}
      <Nav.Link href="/warranty" className="mb-40 py-0">
        <Row>
          <Col className="d-flex justify-content-start">Warranty</Col>
          {showIcons === true && (
            <Col className="d-flex justify-content-end align-self-center">
              <FaChevronRight />
            </Col>
          )}
        </Row>
      </Nav.Link>
      <Nav.Link href="/contact-us" className="mb-40 py-0">
        Contact Us
      </Nav.Link>
      {/*<Nav.Link href="/our-partners" className="mb-40 py-0">
        Our Partners
      </Nav.Link>
      <Nav.Link href="/set-up-services" className="mb-40 py-0">
        Set Up Services
      </Nav.Link>*/}
    </>
  );
}
