import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { FaFilePdf, FaFileVideo, FaTrash } from "react-icons/fa";
import {
    submitWarrantyApi,
    submitWarrantyFilesApi,
  getWarrantyContactApi,
} from "../../lib/warrantyApi";

function WarrantyForm({onSuccess, callbackClaims}) {
  const [category, setCategory] = useState("");
  const [isEmergency, setIsEmergency] = useState("0");
  const [description, setDescription] = useState("");

  const recID = sessionStorage.getItem("recID");
  const accountType = sessionStorage.getItem("accountType");
  const email = accountType === "2" ? sessionStorage.getItem("email") : null;
  // If accountType = 2 (investor) then fetch financialInstanceID from the local storage else fetch financialInstanceID from session storage
  const financialInstanceID = accountType === "2" ? localStorage.getItem("financialInstanceID") : sessionStorage.getItem("userFinancialId");
  //Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setSelectedFiles([]);
    setTimeout(handleClose, 2500);
  };

  //Reference warranty form
  const formRef = useRef(null);

  const inputFileRef = React.useRef(null);
  const [invalidSizes, setInvalidSizes] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleSelectedFiles = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    const files = event.target.files;
    const allowedSize = 100 * 1024 * 1024; // 100 MB
    const invalidSizes = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > allowedSize) {
        invalidSizes.push(file);
      }
    }
    setInvalidSizes(invalidSizes);
    setValidated(false);
  };
  const handleRemove = (index) => {
    const filesCopy = [...selectedFiles];
    filesCopy.splice(index, 1);
    setSelectedFiles(filesCopy);
  };

  //Form
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    //Sanitize warranty form
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      // Allowed file types
      const allowedType = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "video/mp4",
        "video/m4v",
        "video/mov",
      ];

      //Validate file types
      const invalidFiles = selectedFiles.filter(
        (file) => !allowedType.includes(file.type)
      );

      // Allowed file size in bytes
      const allowedSize = 100 * 1024 * 1024; // 100 MB
      let invalidSizes = []; // Declare the variable outside the if block

      if (
        invalidSizes.length > 0 &&
        invalidFiles.length > 0 &&
        selectedFiles.length > 0
      ) {
        invalidSizes = selectedFiles.filter((file) => file.size > allowedSize);
      }
    }

    // If form inputs are valid proceed as true
      setValidated(true);
      if (form.checkValidity() === true && invalidSizes.length == 0) {

      // Show Success Modal
      handleClose(true);
      handleShow(true);

      // Reset the form
      setValidated(false);
      formRef.current.reset();
          let warranty = new FormData();
          

      warranty.append("accountRecID", recID);
      warranty.append("requestCategoryName", category);
      warranty.append("emergency", isEmergency);
      warranty.append("description", description);
      warranty.append("financialInstanceID", financialInstanceID);
          // call api
          const requestID =await submitWarrantyApi(warranty);

      
      // Reset the isEmergency value on valid success submit
      setIsEmergency("0");

      //do toggle toggleAddClaim on valid success submit
      onSuccess();

      //fetch new list of claims on submit handler
          callbackClaims();

          let warrantyFiles = new FormData();
          warrantyFiles.append("accountRecID", recID);
          warrantyFiles.append("financialInstanceID", financialInstanceID);
          warrantyFiles.append("requestID", requestID);
          for (let i = 0; i < selectedFiles.length; i++) {
              warrantyFiles.append("warrantyFiles", selectedFiles[i]);
          }
          if (selectedFiles.length > 0) {
              await submitWarrantyFilesApi(warrantyFiles);
          }
          
    }
  };

    const [contactNumber, setContactNumber] = useState(false);
    const [issueCategory, setIssueCategory] = useState(null);

  //this use effect can be re-purposed with a new useState ex const contactNumber = useCallback(async () => {api call with error check...}
  useEffect(() => {
    getWarrantyContactApi(recID, financialInstanceID, email)
      .then((res) => {
          setContactNumber(res.phone);
          setIssueCategory(res.IssueCategory);
      })
      .catch((e) => {});
  }, [recID, financialInstanceID, email]);

  return (
    <>
      <Form
        ref={formRef}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="text-start"
      >
        <Row className="g-60">
          <Col xs={12} md={6}>
            <Form.Group controlId="homeIssueCategory">
              <Form.Label>Home Issue Category*</Form.Label>
              <Form.Select
                required={true}
                aria-label="homeIssueCategory"
                id="homeIssueCategory"
                placeholder="Home Issue Category"
                className="mb-20"
                onChange={(event) =>
                  setCategory(event.target.selectedOptions[0].text)
                }
                defaultValue={""}
              >
                <option value="" disabled>
                  Home Issue Category
                              </option>
                {issueCategory?.map((issue, key) => (
                    <option value={key}>
                        {issue}
                    </option>
                ))}
                
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="mt-n10 mb-20">
                Select Home Issue Category
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="warrantyEmergency">
              <Form.Label className="text-red fw-bold">
                Is This An Emergency?
              </Form.Label>
              <Form.Select
                aria-label="warrantyEmergency"
                id="warrantyEmergency"
                value={isEmergency}
                onChange={(event) => setIsEmergency(event.target.selectedOptions[0].value)}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row xs={1}>
          <Col>
            <p className="mt-20 mt-md-0 mb-0 fw-regular">
              Emergencies are flooding, potential safety or fire hazards and no
              heat during winter. If you suspect a gas leak, you should
              immediately contact the gas company before reporting it to our
              offices at <span className="fw-bold">{contactNumber}</span>.
            </p>
          </Col>
          <Col>
            <p className="mt-60 fw-regular">
              Please explain in more detail and provide the location of the
              issue you mentioned above. Include a photo or video if avaliable.
            </p>
          </Col>
        </Row>
        <Form.Group controlId="warrantyDetail">
          <Form.Control
            as="textarea"
            required={true}
            maxLength={500}
            placeholder=""
            className="rounded-4"
            onChange={(event) => setDescription(event.target.value.trim())}
            style={{ height: "100px" }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a message
          </Form.Control.Feedback>
        </Form.Group>

        {/* Submit */}
        <Form.Group controlId="userSubmit">
          <Row xs={1}>
            <Col
              xs={12}
              sm={12}
              md={8}
              className="d-flex justify-content-end justify-content-md-start mt-20"
            >
              <Form.Control
                ref={inputFileRef}
                onChange={handleSelectedFiles}
                isInvalid={invalidSizes.length > 0}
                accept="image/jpeg,image/png,application/pdf,video/mp4,video/m4v,video/mov"
                type="file"
                className="d-none"
                multiple
              />
              <Button
                type="button"
                className="text-uppercase"
                onClick={() => inputFileRef.current.click()}
              >
                Add Photo or Video Files
              </Button>
            </Col>

            <Col xs={12} sm={4} className="d-flex justify-content-end mt-20">
              <Button
                className="btn-lg text-uppercase"
                variant="success"
                type="submit"
              >
                Submit
              </Button>
            </Col>
                  </Row>
                  <Row xs={1}><Col
                      xs={12}
                      sm={12}
                      md={8}
                      className="d-flex justify-content-end justify-content-md-start"
                  >Maximum photo size is 100 MB</Col></Row>
        </Form.Group>

        {/* Controls */}
        <Row xs={1} className="mt-20">
          <ul className="list-unstyled">
            {selectedFiles.map((file, index) => (
              <li key={index} className="d-flex align-self-center mb-5">
                <ul className="list-unstyled">
                  <li key={index}>
                    {(() => {
                      switch (file.type) {
                        case "image/png":
                          return (
                            <Image
                              src={URL.createObjectURL(file)}
                              height={25}
                              width={25}
                              alt={file.name}
                              className="me-10 mb-5"
                            />
                          );
                        case "image/jpeg":
                          return (
                            <Image
                              src={URL.createObjectURL(file)}
                              height={25}
                              width={25}
                              alt={file.name}
                              className="me-10 mb-5"
                            />
                          );
                        case "application/pdf":
                          return (
                            <FaFilePdf
                              size={21}
                              className="mt-0 me-5 text-primary"
                            />
                          );
                        case "video/.mov":
                          return (
                            <FaFileVideo
                              size={21}
                              className="mt-0 me-5 text-primary"
                            />
                          );
                        case "video/.m4v":
                          return (
                            <FaFileVideo
                              size={21}
                              className="mt-0 me-5 text-primary"
                            />
                          );
                        case "video/.mp4":
                          return (
                            <FaFileVideo
                              size={21}
                              className="mt-0 me-5 text-primary"
                            />
                          );
                        default:
                          return;
                      }
                    })()}
                    {file.name}
                    <span className="ms-10">
                      {file.size < 1024 * 1024
                        ? `${(file.size / 1024).toFixed(2)} KB`
                        : `${(file.size / (1024 * 1024)).toFixed(2)} MB`}
                    </span>
                    <FaTrash
                      className="text-red ms-5 cursor-pointer"
                      onClick={() => handleRemove(index)}
                    >
                      Remove
                    </FaTrash>
                  </li>
                  <li key={`error-${index}`}>
                    {invalidSizes.includes(file) && (
                      <Col className="text-start text-red fw-bold mt-5">
                        File size must be less than 100 MB
                      </Col>
                    )}
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </Row>
      </Form>

      {/* Success Modal */}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="text-bg-primary text-center fw-bold rounded-4 shadow-lg py-40 fs-20 border">
          Your Warranty Claims
          <br /> have been submitted.
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WarrantyForm;
