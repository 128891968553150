import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import { signUpApi } from "../../lib/mysmhApi";

export default function RegisterForm(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [unitApt, setUnitApt] = useState("");
  const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");

  const [regionalLocation, setRegionalLocation] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateLocations, setStateLocations] = useState([]);

  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setEmail(props.useremail);
  }, [props.useremail]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();

      //Close parent modal
      props.closeModal();
      //Trigger form submit on Register Modal, the actions based of a registration is succesfull lives in the register modal please keep that in mind
        props.onFormSubmit();

      //Make api call to post registration form
      await signUpApi(
        firstName.trim(),
        lastName.trim(),
        email,
        phoneNumber,
        address.trim(),
       // unitApt.trim(),
        city.trim(),
          selectedState,
        zipCode,
          regionalLocation
      );
    }
  };
  return (
    <Form
      ref={formRef}
      validated={validated}
      onSubmit={handleSubmit}
      noValidate
    >
      <Row xs={1} md={2}>
        <Col>
          <Form.Group controlId="firstName">
            <Form.Label>First Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name*"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter First Name
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-10 mt-sm-0" controlId="LastName">
            <Form.Label>Last Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name*"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter Last Name
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-10">
        <Col xs={12} md={6}>
          <Form.Group controlId="email">
            <Form.Label>Email Address*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email Address*"
              className="text-grey bg-light"
              value={email}
              autoComplete="false"
              readOnly
            />
            {/* <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter Email Address
            </Form.Control.Feedback> */}
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="phoneNumber" className="mt-10 mt-sm-0">
            <Form.Label>Phone Number*</Form.Label>
            <InputMask
              mask="999-999-9999"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            >
              {(inputProps) => (
                <Form.Control
                  {...inputProps}
                  placeholder="999-999-9999"
                  type="tel"
                  required
                />
              )}
            </InputMask>
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter a Valid Phone Number
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-10">
        <Col xs={12}>
          <Form.Group controlId="address">
            <Form.Label>Address*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Address*"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter Address
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

       {/* <Col xs={12} md={3} className="mt-10">
          <Form.Group controlId="unitApt">
            <Form.Label>Unit/Apt</Form.Label>
            <Form.Control
              type="text"
              placeholder="#"
              value={unitApt}
              onChange={(event) => setUnitApt(event.target.value)}
            />
          </Form.Group>
        </Col>*/}

        <Col xs={12} md={5} className="mt-10">
          <Form.Group controlId="city">
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              placeholder="City*"
              value={city}
              onChange={(event) => setCity(event.target.value)}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter City
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col className="mt-10">
          <Form.Group controlId="zipCode">
            <Form.Label>Zip Code*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Zip Code*"
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}
              maxLength={5}
              required
            />
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Enter Zip Code
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row xs={1} md={2} className="mt-10">
        <Col>
          <Form.Group controlId="stateLocation">
            <Form.Label>State/Province/Region*</Form.Label>
            <Form.Select
              placeholder="State/Province/Region*"
              value={selectedState}
              onChange={(event) => {
                const selectedValue = Object.keys(
                  (props.locations && props.locations.location) || {}
                ).find((state) => state.split(":")[1] === event.target.value);

                setSelectedState(event.target.value);
                setStateLocations(
                  (props.locations &&
                    props.locations.location[selectedValue]) ||
                    []
                  );
                  setRegionalLocation("");
              }}
              required
            >
              <option value="" disabled>
                State/Province/Region
              </option>
              {Object.keys(
                (props.locations && props.locations.location) || {}
              ).map((state, i) => {
                const [stateFullName, stateAbbreviation] = state.split(":");
                return (
                  <option key={i} value={stateAbbreviation}>
                    {stateFullName}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Select State/Province/Region
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="regionalLocation" className="mt-10 mt-sm-0">
            <Form.Label>Regional Location*</Form.Label>
            <Form.Select
              placeholder="Regional Location"
              value={regionalLocation}
              className={!selectedState && "text-grey"}
              onChange={(event) => setRegionalLocation(event.target.value)}
              required
              disabled={!selectedState} // Add this line
            >
              <option value="" disabled>
                Regional Location
              </option>
                          {stateLocations.map((location, i) => {
                              const metroAreaLocation = location.split(":")[0];
                             return (
                  
                                 <option key={i} value={location}>
                              {metroAreaLocation}
                </option>
              )})}
            </Form.Select>
            <Form.Control.Feedback
              type="invalid"
              className="text-red fw-bold mb-0 p-0"
            >
              Select Regional Location
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Col className="text-center">
        <Button
          variant="success"
          size="md"
          className="mt-50 mb-45"
          type="submit"
        >
          SUBMIT
        </Button>
      </Col>
    </Form>
  );
}
