import React, { useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { updatePasswordApi } from "../../lib/mysmhApi";

export default function AccountChangePasswordModal({
  oldPassword,
  setPassword,
  show,
  onHide,
}) {
  const formChangePassRef = useRef(null);
  const [validatedChangePass, setValidatedChangePass] = useState(false);

  //Modal Confirm
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  function handleResetConfirmModalClose() {
    onHide();
    setShowResetConfirm(false);
  }

  //Form Controls
  const [userOldPassword, setUserOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const toggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleUserOldPassword = (event) => {
    setUserOldPassword(event.target.value.trim());
  };

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleUserPassword = async (event) => {
    setUserPassword(event.target.value.trim());
  };

  //Submit
  const handleChangePassSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (userOldPassword === "") {
      setShowErrorMessage(true);
      setErrorMessage("Enter Password");
    } else {
      if (oldPassword !== userOldPassword) {
        setValidatedChangePass(false);

        setShowErrorMessage(true);
        setErrorMessage("Wrong Password");
      } else {
        setShowErrorMessage(false);
        setValidatedChangePass(true);
        if (form.checkValidity() === true) {
          event.preventDefault();

          await updatePasswordApi(
            sessionStorage.getItem("recID"),
            userOldPassword,
            userPassword
          );
          setPassword(userPassword);
          setUserPassword("");
          setUserOldPassword("");
          formChangePassRef.current.reset();

          onHide();
          handleClose();

          setShowResetConfirm(true);
          setTimeout(() => {
            setShowResetConfirm(false);
          }, 2500);
        }
      }
    }
  };

  const handleClose = () => {
    onHide();
    setUserPassword("");
    setUserOldPassword("");
    formChangePassRef.current.reset();
    setValidatedChangePass(false);
  };

  return (
    <>
      {/* Edit Password Form */}
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Body className="shadow-lg p-0 bg-primary rounded-top">
          <Modal.Header className="text-white p-40">
            <Col className="justify-content-start align-self-center" />
            <Col className="m-auto text-center fw-bold align-self-center d-flex">
              <span className="fs-25 text-nowrap">Edit Password</span>
            </Col>
            <Col className="justify-content-end text-end" />
          </Modal.Header>
          <Col
            xs={12}
            className="bg-light px-30 pt-30 pb-35 pb-0 rounded-0 rounded-bottom rounded-lg"
          >
            <Form
              noValidate
              ref={formChangePassRef}
              validated={validatedChangePass}
              onSubmit={handleChangePassSubmit}
            >
              {/* Old Password */}
              <Form.Group controlId="userOldPassword">
                <Form.Label>Old Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    isInvalid={showErrorMessage ? true : false }
                    className="p-15 rounded-start rounded-0 border border-light-secondary border-start border-1 border-end-0"
                    type={showOldPassword ? "text" : "password"}
                    value={userOldPassword}
                    onChange={handleUserOldPassword}
                    placeholder="Enter Old Password"
                    autoComplete="off"
                    required
                  />
                  <InputGroup.Text
                    className="bg-white text-grey rounded-end rounded-0 border border-light-secondary border-end border-1 border-start-0"
                    onClick={toggleOldPassword}
                  >
                    {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {showErrorMessage && (
                <Col className="text-red fw-bold mb-0 mt-15 fs-15 text-center">
                  {errorMessage}
                </Col>
              )}
              
              {/* New Password */}
              <Form.Group controlId="userNewPassword" className="mt-20">
                <Form.Label>New Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="p-15 rounded-start rounded-0 border border-light-secondary border-start border-1 border-end-0"
                    type={showPassword ? "text" : "password"}
                    value={userPassword}
                    onChange={handleUserPassword}
                    placeholder="Enter New Password"
                    autoComplete="off"
                    required
                  />
                  <InputGroup.Text
                    className="bg-white text-grey rounded-end rounded-0 border border-light-secondary border-end border-1 border-start-0"
                    onClick={togglePassword}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                  <Form.Control.Feedback
                    type="invalid"
                    className="text-red fw-bold mb-0 p-0"
                  >
                    Enter New Password
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Col xs={12} md={4} className="m-auto">
                <Row xs={2} className="text-center mt-35">
                  <Col>
                    <Button type="submit" variant="success" size="sm">
                      SAVE
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Modal.Body>
      </Modal>

      {/* Confirm Message */}
      <Modal
        show={showResetConfirm}
        onHide={handleResetConfirmModalClose}
        size="lg"
        centered
      >
        <Modal.Body className="text-bg-primary text-center rounded-4 shadow-lg p-50">
          <Row>
            <Col xs={12}>
              <p className="mb-0 fs-20 fw-bold border rounded-4 p-30 border-1 border-white">
                Your password has been changed
              </p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
