import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FaCamera, FaEye, FaEyeSlash, FaPenSquare } from "react-icons/fa";
import AccountChangePasswordModal from "./AccountChangePasswordModal";
import {
  getUserAccountDetails,
  updateProfileApi,
  updateProfilePhotoApi,
} from "../../lib/mysmhApi";
import InputMask from "react-input-mask";
import useNameAbbr from "../../hooks/useNameAbr";

export default function AccountProfileForm() {
  const nameAbr = useNameAbbr();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [unitApt] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailCommunication, setEmailChecked] = useState(false);
  const [isTextCommunication, setTextChecked] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    getUserAccountDetails(sessionStorage.getItem("recID"))
      .then((res) => {
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setEmail(res.email);
        setPassword(res.password);
        setPhoneNumber(res.phoneNumber);
        setAddress(res.address);
        setState(res.state);
        setCity(res.city);
        setZipCode(res.zipCode);
        setEmailChecked(res.isEmailCommunication === "True" ? true : false);
        setTextChecked(res.isTextCommunication === "True" ? true : false);
      })
      .catch((error) => {});
  }, []);
  
  const formRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const handleSubmit = async (event) => {
    //Add mapi call here
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(false);
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      event.preventDefault();
      await updateProfileApi(
        sessionStorage.getItem("recID"),
        firstName,
        lastName,
        email,
        phoneNumber,
        address,
        unitApt,
        city,
        state,
        zipCode,
        isEmailCommunication.toString(),
        isTextCommunication.toString()
      );
      handleClose(true);
      handleShow(true);
    }
  };

  const inputFileRef = React.useRef(null);
  const [invalidSize, setInvalidSize] = useState(false);
  const [invalidType, setInvalidType] = useState(false);

  let userSessionPhoto = sessionStorage.getItem("profileImageUrl");
  const [userProfilePhoto, setUserProfilePhoto] = useState(userSessionPhoto);

  const handleSelectedFile = async (event) => {
    const file = event.target.files[0];
    const allowedSize = 10 * 1024 * 1024;
    const allowedTypes = ["image/jpeg", "image/png"];

    let imageData = new FormData();
    imageData.append("recID", sessionStorage.getItem("recID"));
    imageData.append("profilePicture", file);

    if (allowedTypes.includes(file.type)) {
      if (file.size > allowedSize) {
        setInvalidSize(true);
      } else {
        let userAccount = await updateProfilePhotoApi(imageData);
        let imgkitpath = userAccount.profileImageUrl;
        userSessionPhoto = `${imgkitpath}?t=${new Date().getTime()}}`;
        sessionStorage.setItem("profileImageUrl", userSessionPhoto || "");
      }
    } else {
      setInvalidType(true);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newPhoto = sessionStorage.getItem("profileImageUrl");
      if (newPhoto !== userProfilePhoto) {
        setUserProfilePhoto(newPhoto);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [userSessionPhoto, userProfilePhoto]);

  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleUserPassword = (event) => {
    setPassword(event.target.value.trim());
  };
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const handleResetPasswordModalShow = () => setShowResetPasswordModal(true);
  useEffect(() => {
    let timer;
    if (show) {
      timer = setTimeout(() => {
        handleClose();
        setValidated(false); // clear form
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [show]);

  return (
    <>
      <Form
        ref={formRef}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <h3>Account Settings</h3>
        <p className="mb-25">
          View and update your account details, profile and more.
        </p>

        {/* Basic Info */}
        <Card className="shadow-sm rounded rounded-4 border-secondary-10">
          <Card.Body className="bg-light border-0 p-0 rounded rounded-4">
            <Col className="text-bg-primary fs-25 px-20 py-20 rounded-0 rounded-top">
              Basic Info
            </Col>
            <Col className="p-20">
              <Row className="g-40">
                {/* Account Card */}
                <Col sm={12} md={4} style={{ maxWidth: 260 }}>
                  <Card className="shadow border-secondary-10 fs-16">
                    <Col className="bg-light-blue text-center rouned-0 rouned-4">
                      {userSessionPhoto ? (
                        <Col>
                          <Image
                            className="rounded-circle m-auto mb-n50 mt-15"
                            src={userSessionPhoto}
                            width={120}
                            height={120}
                          />
                        </Col>
                      ) : (
                        <Col
                          id="profile"
                          className="text-primary d-flex justify-content-center bg-tertiary rounded-circle text-center m-auto px-0 mt-15 mb-n50 fw-light"
                          style={{
                            width: 120,
                            height: 120,
                            fontSize: 50,
                            paddingTop: 20,
                            alignContent: "center",
                            textAlign: "center",
                            borderRadius: "100%",
                          }}
                        >
                          {nameAbr}
                        </Col>
                      )}
                      <Col>
                        <Button
                          onClick={() => inputFileRef.current.click()}
                          className="fs-15 justify-content-center text-bg-primary rounded-circle align-self-center text-center m-auto px-0 mt-5 d-flex"
                          style={{
                            top: 10,
                            left: 40,
                            width: 30,
                            height: 30,
                            paddingTop: 7,
                            alignContent: "center",
                            textAlign: "center",
                            borderRadius: "100%",
                            position: "relative",
                          }}
                        >
                          <FaCamera size={16} />
                        </Button>
                        <Form.Control
                          id="files"
                          type="file"
                          ref={inputFileRef}
                          onChange={handleSelectedFile}
                          accept="image/jpeg,image/png"
                          className="d-none"
                        />
                      </Col>
                    </Col>
                    <Card.Body>
                      <ul className="list-unstyled mt-10 mb-5 text-center fs-16">
                        <li>Profile Image</li>
                      </ul>
                      {invalidSize && (
                        <Col className="text-center text-red">Invalid Size</Col>
                      )}
                      {invalidType && (
                        <Col className="text-center text-red">
                          Invalid File Type
                        </Col>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Row xs={1} md={2}>
                    <Col>
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name*"
                          size="lg"
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                          required
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter First Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="pt-10 pt-md-0">
                      <Form.Group controlId="LastName">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="Last Name*"
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                          required
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter Last Name
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={1} md={2} className="mt-10">
                    <Col>
                      <Form.Group controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="Email Address"
                          value={email}
                          className="text-grey bg-light"
                          readOnly
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter Email Address
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col className="pt-10 pt-md-0">
                      <Form.Group
                        controlId="phoneNumber"
                        className="mt-10 mt-sm-0"
                      >
                        <Form.Label>Phone Number*</Form.Label>
                        <InputMask
                          mask="999-999-9999"
                          value={phoneNumber}
                          onChange={(event) =>
                            setPhoneNumber(event.target.value)
                          }
                        >
                          {(inputProps) => (
                            <Form.Control
                              {...inputProps}
                              size="lg"
                              placeholder="999-999-9999"
                              type="tel"
                              required
                            />
                          )}
                        </InputMask>
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter a Valid Phone Number
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-10">
                    <Col xs={12}>
                      <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="Address"
                          value={address}
                          className="text-grey bg-light"
                          readOnly
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter Address
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row xs={1} md={3} className="mt-10">
                    <Col className="mt-10 mt-md-0">
                      <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="City"
                          value={city}
                          className="text-grey bg-light"
                          readOnly
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter City
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="mt-10 mt-md-0">
                      <Form.Group controlId="zipCode">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="Zip Code"
                          maxLength={5}
                          value={zipCode}
                          className="text-grey bg-light"
                          readOnly
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter Zip Code
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="mt-10 mt-md-0">
                      <Form.Group controlId="stateLocation">
                        <Form.Label>State/Province/Region</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          placeholder="State/Province/Region"
                          value={state}
                          className="text-grey bg-light"
                          readOnly
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-red fw-bold mb-0 p-0"
                        >
                          Enter State
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-10"></Row>
                </Col>
              </Row>
            </Col>
          </Card.Body>
        </Card>

        {/* Password Panel */}
        <Card className="shadow-sm rounded rounded-4 border-secondary-10 my-45">
          <Card.Body className="bg-light border-0 p-0 rounded rounded-4">
            <Col className="text-bg-primary fs-25 px-20 py-20 rounded-0 rounded-top">
              Login Info
            </Col>
            <Col xs={12} md={5} className="p-20">
              <Form.Group controlId="userPassword">
                <Form.Label>Password*</Form.Label>
                <InputGroup className="border border-1 rounded-3 border-light-secondary">
                  <Form.Control
                    className="p-15 rounded-left border-0"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    value={password}
                    readOnly
                    onChange={handleUserPassword}
                    placeholder="Enter Password*"
                  />
                  <InputGroup.Text
                    className="rounded-right cursor-pointer bg-white text-grey border-0"
                    onClick={togglePassword}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>

                  <InputGroup.Text
                    className="rounded-right cursor-pointer bg-white text-primary border-0"
                    onClick={handleResetPasswordModalShow}
                  >
                    <FaPenSquare size={20} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Card.Body>
        </Card>

        {/* Communication */}
        {/*<Card className="shadow-sm rounded rounded-4 border-secondary-10">
                    <Card.Body className="bg-light border-0 p-0 rounded rounded-4">
                        <Col className="text-bg-primary fs-25 px-20 py-20 rounded-0 rounded-top">
                            Communication Preferences
                        </Col>
                        <Col className="p-20">
                            <Row>
                                <Col xs={6} className="align-self-center">
                                    Choose which type of emails and texts you would like to
                                    receive from us.
                                </Col>
                                <Col className="d-flex justify-content-end text-center">
                                    <Col xs={9} md={4}>
                                        <Row sm={2}>
                                            <Col>
                                                <Form.Label className="fw-bold text-primary fs-14">
                                                    Email
                                                </Form.Label>
                                                <Form.Check
                                                    size="lg"
                                                    type="switch"
                                                    id="emailCheck"
                                                    checked={isEmailCommunication}
                                                    onChange={(event) =>
                                                        setEmailChecked(event.target.checked)
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label className="fw-bold text-primary fs-14">
                                                    Text
                                                </Form.Label>
                                                <Form.Check
                                                    size="lg"
                                                    type="switch"
                                                    id="textCheck"
                                                    checked={isTextCommunication}
                                                    onChange={(event) =>
                                                        setTextChecked(event.target.checked)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Card.Body>
                </Card>*/}
        <Col xs={12} className="text-center">
          <Button variant="success" className="mb-50" type="submit">
            SAVE CHANGES
          </Button>
        </Col>
      </Form>

      <AccountChangePasswordModal
        oldPassword={password}
        setPassword={setPassword}
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
      />
      {/* Success Modal */}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body className="text-bg-primary text-center fw-bold rounded-4 shadow-lg py-40 fs-20 border">
          Your details
          <br /> have been saved.
        </Modal.Body>
      </Modal>
    </>
  );
}
