import React, { useState } from "react";
import { Button, Card, Col, Image, Modal, Row } from "react-bootstrap";
import { FaDownload, FaWindowClose } from "react-icons/fa";

export default function HomeDetailCard({
  props,
  showMoreDetails,
  showCommunityDetails,
  showAddress,
  cardTitle,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let floorPlanPdf = props?.floorPlanPdf;

  return (
    <>
      <h3 className={`${showAddress ? "pb-0 mb-0" : "mb-30"}`}>{cardTitle}</h3>
      {showAddress && (
        <p className={`${showAddress && "mb-15"}`}>{props?.address}</p>
      )}
      <Card
        id={showAddress === false ? "homeDetailCard" : undefined}
        className="shadow-sm rounded-4 border-secondary-10 mb-30"
      >
        <Image
          height="260"
          width="390"
          className="p-15 rounded-5 img-fluid w-100"
          loading="eager"
          style={{ objectFit: "contain" }}
          src={props?.homeImage}
        />
        <Card.Body className="text-center pt-5">
          <Row className="gap-0 g-0">
            {!showAddress && (
              <Col xs={12} className="fw-bold pb-20">
                {props?.address}
              </Col>
            )}
            <Col
              xs={6}
              sm={3}
              className="border border-start-0 border-top-0 border-bottom-0 mb-15 mb-md-0"
            >
              <Col className="fw-bold">{props?.housePlan}</Col>
              <Col className="fs-13">House Plan</Col>
            </Col>
            <Col
              xs={6}
              sm={3}
              className="border border-xs-0 border-start-xs-0 border-start-0 border-top-0 border-bottom-0"
            >
              <Col className="fw-bold">{props?.elevation}</Col>
              <Col className="fs-13">Elevation</Col>
            </Col>
            <Col
              xs={6}
              sm={3}
              className="border border-start-xs-0 border-start-0 border-top-0 border-bottom-0"
            >
              <Col className="fw-bold">{props?.orientation}</Col>
              <Col className="fs-13">Orientation</Col>
            </Col>
            <Col xs={6} sm={3}>
              <Col className="fw-bold">{props?.homesite}</Col>
              <Col className="fs-13">Homesite</Col>
            </Col>
          </Row>
          {showMoreDetails && (
            <Row className="gap-0 g-0 mt-20">
              <Col
                xs={6}
                sm={3}
                className="border border-start-0 border-top-0 border-bottom-0 mb-15 mb-md-0"
              >
                <Col className="fw-bold">{props?.sqFt}</Col>
                <Col className="fs-13">Sq.Ft.</Col>
              </Col>
              <Col
                xs={6}
                sm={3}
                className="border border-xs-0 border-start-xs-0 border-start-0 border-top-0 border-bottom-0"
              >
                <Col className="fw-bold">{props?.bedroomCount}</Col>
                <Col className="fs-13">Beds</Col>
              </Col>
              <Col
                xs={6}
                sm={3}
                className="border border-start-xs-0 border-start-0 border-top-0 border-bottom-0"
              >
                <Col className="fw-bold">
                  {props?.bathFullCount} Full {props?.bathHalfCount} Half
                </Col>
                <Col className="fs-13">Baths</Col>
              </Col>
              <Col xs={6} sm={3}>
                <Col className="fw-bold">{props?.garageCount}</Col>
                <Col className="fs-13">Garage</Col>
              </Col>
            </Row>
          )}
          {showCommunityDetails ? (
            <>
              <Row className="mt-20">
                <Col
                  xs={6}
                  md={6}
                  className="border border-start-xs-0 border-start-0 border-top-0 border-bottom-0"
                >
                  {props?.neighborhood !== null ? (
                    <>
                      <Col className="fw-bold">{props?.neighborhood}</Col>
                      <Col className="fs-13">Neighborhood</Col>
                      <Col className="py-15">
                        <Button
                          type="button"
                          href="/neighborhood"
                          size="sm"
                          className="text-uppercase"
                        >
                          View More
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="fw-bold">{props?.communityName}</Col>
                      <Col className="fs-13">Community</Col>
                    </>
                  )}
                </Col>

                <Col xs={6} md={6}>
                  <Col className="fw-bold">{props?.price}</Col>
                  <Col className="fs-13">Price</Col>
                  <Col className="py-15">
                    <Button
                      type="button"
                      href="/home-resources"
                      size="sm"
                      className="text-uppercase"
                    >
                      View More
                    </Button>
                  </Col>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {floorPlanPdf !== null && floorPlanPdf !== "" ? (
                <Button
                  type="button"
                  variant="success"
                  className="mt-40"
                  onClick={handleShow}
                >
                  VIEW FLOORPLAN
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
          <Col sm={12} className="text-center text-inactive mt-45">
            <p className="pt-10 pb-0 mb-0">
              Image may be a rendering or photo of a home similar to your home.
            </p>
          </Col>
        </Card.Body>
      </Card>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered
        className="p-30"
      >
        <Modal.Body className="shadow-lg p-0 bg-primary rounded-top">
          <Modal.Header className="text-white py-20">
            <Col className="justify-content-start align-self-center" />
            <Col className="text-center fw-bold align-self-center">
              <span className="fs-25">Your Floorplan</span>
            </Col>
            <Col className="justify-content-end text-end">
              <FaWindowClose className="cursor-pointer" onClick={handleClose} />
            </Col>
          </Modal.Header>
          <Col
            xs={12}
            className="bg-light rounded-0 rounded-bottom rounded-lg text-center"
          >
            <object
              aria-label={floorPlanPdf}
              data={floorPlanPdf}
              type="application/pdf"
              width="100%"
              height="780"
            ></object>
            <Col xs={12} sm={7} className="text-center m-auto">
              <Button
                type="button"
                variant="success"
                className="mb-40 btn-block w-100"
                href={floorPlanPdf}
                target="_blank"
                onClick={handleClose}
              >
                <FaDownload /> DOWNLOAD FLOORPLAN
              </Button>
            </Col>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
