import React from "react";
import { Button, Col, Row } from "react-bootstrap";

export default function SubmitWarrantyBtn() {
  return (
    <Row className="ps-10">
      <Col xs={12} sm={12} className="px-0 pe-15" style={{ maxWidth: 448 }}>
        <Button
          className="btn-warranty border border-2 p-40 text-primary shadow-sm rounded rounded-4 border-secondary-10 w-100 fs-20"
          href={"/warranty/warranty-claims"}
        >
          <span className="fw-bolder fs-20 text-uppercase">Submit or View</span>
          <br />
          <span>Warranty Requests</span>
        </Button>
      </Col>
    </Row>
  );
}
