import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

//Pass deconstructor {props} on line 5 to map data in return
export default function UserDepositCalculator() {
  const [closeAmount, setCloseAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [downPaymentPercent, setDownPaymentPercent] = useState("");

  useEffect(() => {
    setDownPaymentPercent("20%");
    setDownPayment("$47,839.40");
    setCloseAmount("$37,560.60");
  }, []);

  return (
    <Form>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlid="purchasePrice">
            <Form.Label>Purchase Price</Form.Label>
            <Form.Control type="text" placeholder="$237,000" size="lg" />
          </Form.Group>
        </Col>
        <Col
          xs={1}
          className="text-center align-self-center fw-bold d-sm-none d-md-block d-lg-block mt-20"
        />
        <Col xs={12} md={5}>
          <Form.Group controlid="currentPayment">
            <Form.Label>Amount Already Paid Towards Down Payment</Form.Label>
            <Form.Control type="text" placeholder="$9,839.40" size="lg" />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="downPayment">
        <Row className="mt-20">
          <Col xs={12} md={6}>
            <Form.Label>Down Payment %</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder=""
              value={downPaymentPercent}
              onChange={(event) => setDownPaymentPercent(event.target.value)}
            />
          </Col>
          <Col
            xs={1}
            className="text-center align-self-center fw-bold pt-30 pb-5 px-0"
          >
            OR
          </Col>
          <Col>
            <Form.Label>Down Payment $</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder=""
              value={downPayment}
              onChange={(event) => setDownPayment(event.target.value)}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group controlId="amountClose">
        <Row className="mt-20">
          <Col xs={12} style={{ maxWidth: 460 }}>
            <Form.Label className="text-primary fw-bold">
              Total Amount Needed to Close*
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              placeholder=""
              value={closeAmount}
              onChange={(event) => setCloseAmount(event.target.value)}
            />
          </Col>
        </Row>
        <Col className="mt-30 text-center fs-13">
          *Need Disclaimer from legal
        </Col>
      </Form.Group>
    </Form>
  );
}
