import React, { useState, useCallback } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FaChevronCircleDown, FaPlusCircle } from "react-icons/fa";
import Page from "../components/Layout/Page";
import { getSubmittedWarrantyApi } from "../lib/warrantyApi";
import WarrantyForm from "../components/Warranty/WarrantyForm";
import WarrantyRequestTable from "../components/Warranty/WarrantyRequestTable";

export default function WarrantyClaims() {
  const [claims, setClaims] = useState([]);
  const [isAddClaimOpen, setIsAddClaimOpen] = useState(false);
  const [isFetchExecuted, setIsFetchExecuted] = useState(false);

  const recID = sessionStorage.getItem("recID");
  const accountType = sessionStorage.getItem("accountType");
  // If accountType = 2 (investor) then financialInstanceID fetch from the local storage else assign as null
  const financialInstanceID = accountType === "2" ? localStorage.getItem("financialInstanceID") : null;

  const fetchClaims = useCallback(async () => {
    try {
      const res = await getSubmittedWarrantyApi(recID, financialInstanceID);
      setClaims(res);
      if (res.length === 0) {
        setIsAddClaimOpen(true);
      }
    } catch (e) {
      // console.log(e)
    }
  }, [recID, financialInstanceID]);

  if (!isFetchExecuted) {
    setIsFetchExecuted(true);
    fetchClaims();
  }

  const toggleAddClaim = () => {
    setIsAddClaimOpen(!isAddClaimOpen);
  };


  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Warranty Claims | My Stanley Martin</title>
      </Helmet>
      <Col xs={12} md={12} className="me-auto max-view-width">
        <Col className="ps-15 ps-md-50">
          <h3>Warranty Claims</h3>
        </Col>
        <Col xs={12} md={11} className="ps-15 ps-md-50 pb-50 mt-0">
          {/* Add a claim */}
          <Accordion activeKey={isAddClaimOpen ? "1" : "2"} flush>
            <Card className="border-0">
              <Card.Header className="p-0 border-0 bg-transparent">
                <Row className="justify-content-md-center">
                  <Col xs lg="2" />
                  <Col md="auto" />
                  <Col xs lg="2" />
                </Row>
                <Col className="fw-bold text-primary">
                  <CustomToggle
                    isOpen={isAddClaimOpen}
                    onClick={toggleAddClaim}
                  >
                    Add A Claim
                  </CustomToggle>
                </Col>
                <Col />
                <Col className="align-self-center d-flex" />
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="bg-light border-1 border rounded-4 border-secondary-10 mt-30 px-30 px-md-60 py-30">
                  <Col xs={12} className="m-0">
                    <WarrantyForm
                      onSuccess={toggleAddClaim}
                      callbackClaims={() => fetchClaims()}
                    />
                  </Col>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

          {/* Requests Table */}
          <Accordion className="mt-30" defaultActiveKey="2" flush>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <WarrantyRequestTable warrantyClaims={claims} />
              </Card.Body>
            </Accordion.Collapse>
          </Accordion>
        </Col>
      </Col>
    </Page>
  );
}

function CustomToggle({ isOpen, onClick, children }) {
  return (
    <Button
      size="lg"
      variant="transparent"
      className="p-0 d-flex justify-content-end text-primary"
      onClick={onClick}
    >
      {isOpen ? (
        <FaChevronCircleDown size={24} className="pt-5 me-5" />
        ) : (
        <FaPlusCircle size={24} className="pt-5 me-5" />
      )}
      {children}
    </Button>
  );
}
