import React from "react";
import { Button, Card, CardImg, Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

function Slider({
    props,
    showText,
    showImg,
    showTimeStamp,
    showCtaButton,
    showBackground,
    recordDetails,
    showIndicators,
    showControls,
    variantSliderControl,
    fade,
    slide,
    rowSm,
    rowMd,
    rowLg,
    bodyPadding,
    showCardHeader,
    rowPosition,
    buttonText,
    buttonClassName,
    variant,
}) {
    const slides = props.map((row) => {
        const subSlides = row.slide.map((slide, index) => {
            return (
                <Card
                    key={index}
                    className={`p-0 ${!showBackground &&
                        "rounded rounded-4 border-secondary-10 shadow-0 border-0 bg-transparent m-0"
                        }`}
                >
                    {showCardHeader && (
                        <Card.Header className="bg-light-blue fw-bold py-15 px-20">
                            {slide.headertitle}
                        </Card.Header>
                    )}

                    <Card.Body className={`text-regular`}>
                        {showImg && (
                            <CardImg
                                height={440}
                                width={700}
                                className="img-fluid mb-20 rounded-0"
                                src={slide.img}
                                style={{
                                    width: "700px",
                                    height: "auto",
                                }}
                            />
                        )}
                        {showText && (
                            <div key={slide.title}>
                                <Col>
                                    <b className="text-primary mt-20 mb-15">{slide.title}</b>
                                </Col>
                                <Col className="text-secondary">
                                    <b>{slide.time}</b>
                                </Col>
                                <Col className="mb-10">
                                    <p className="mb-15">{slide.desc}</p>

                                    {showCtaButton !== false && (
                                        <Button
                                            href={slide.href}
                                            variant={variant}
                                            className={buttonClassName}
                                        >
                                            {buttonText}
                                        </Button>
                                    )}
                                </Col>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            );
        });

        const groupedSlides = [];
        let i = 0;
        while (i < subSlides.length) {
            groupedSlides.push(
                <Carousel.Item>
                    <Row
                        sm={rowSm}
                        md={rowMd}
                        lg={rowLg}
                        className={`d-flex justify-content-${rowPosition ?? "center"
                            } gap-40 gap-md-40 m-auto`}
                    >
                        {subSlides.slice(i, i + 3)}
                    </Row>
                </Carousel.Item>
            );
            i += 4;
        }
        return groupedSlides;
    });
    return (
        <>
            <Carousel
                interval={null}
                touch={true}
                wrap={false}
                controls={showControls}
                indicators={showIndicators}
                pause={"hover"}
                {...(fade === true ? (fade = { fade }) : (slide = { slide }))}
                variant={variantSliderControl ? { variantSliderControl } : "dark"}
            >
                {slides}
            </Carousel>
            {showTimeStamp && (
                <Col xs={12}>
                    Last Photo Added at {recordDetails.lastUpdated}
                </Col>
            )}
        </>
    );
}

export default Slider;
