import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SiteName from "../SiteName";
import TempPasswordForm from "./TempPasswordForm";

export default function TempPasswordModal({ show, onHide, useremail }) {
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={onHide}
        size="lg"
        centered
      >
        <Modal.Body className="text-bg-primary text-center rounded-4 shadow-lg pb-40 px-5 pt-40 fs-20">
          <Row>
            <Col xs={12}>
              <SiteName />
            </Col>
            <Col xs={12} className="px-40 px-md-85">
              <TempPasswordForm useremail={useremail} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
