import React from "react";
import { Col } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { regAlphaNumb } from "../../lib/Helpers";

export default function NavSiteCrumbs({ root, cat, subcat, tertcat }) {
  let accountType = sessionStorage.getItem("accountType");
  return (
    <Col
      id="breadcrumbs"
      sm={12}
      className="bg-primary mb-50 text-capitalize px-15"
    >
      <ul className="list-unstyled list-inline mb-0">
        <li className="list-inline-item align-self-center text-secondary">
          <a
            href={
              accountType === "1"
                    ? "/my-home"
                    : accountType === "2"
                            ? "/my-homes"
                            : "/warranty"
            }
            className="btn text-secondary link-secondary px-0 pt-5"
            target="_parent"
          >
            <FaHome size={18} />
            {root}
          </a>
        </li>
        <li className="list-inline-item align-self-center text-secondary">/</li>
        <li className="list-inline-item align-self-center">
          <a
            href={`/${cat}`}
            className={subcat ? "text-secondary" : "text-white"}
            target="_parent"
          >
            {cat.replace(regAlphaNumb, " ")}
          </a>
        </li>
        {subcat && (
          <>
            <li className="list-inline-item align-self-center text-secondary">
              /
            </li>
            <li
              className={`${
                !tertcat ? "text-white" : "text-secondary"
              } list-inline-item align-self-center`}
            >
              {subcat.replace(regAlphaNumb, " ")}
            </li>
          </>
        )}
        {tertcat && (
          <>
            <li className="list-inline-item align-self-center text-secondary">
              /
            </li>
            <li className="list-inline-item align-self-center text-white">
              {tertcat.replace(regAlphaNumb, " ")}
            </li>
          </>
        )}
      </ul>
    </Col>
  );
}
