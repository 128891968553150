import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "react-bootstrap";
import Page from "../components/Layout/Page";
import {
  HomeDetailCard,
  HomeScheduleCard,
  HomeStatusCard,
} from "../components/Card";
import WarrantyContent from "../components/Warranty/WarrantyDetails";
import Hero from "../components/Hero";
import { homeDetailsApi } from "../lib/homeApi";

export default function Home() {
  const [userHomeDetails, setUserHomeDetails] = useState("");
  const [userHomeStatus, setUserHomeStatus] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userMemoryBookPhotos, setUserMemoryBookPhotos] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userMemoryBookDetails, setUserMemoryBookDetails] = useState("");
  const [heroImg, setHeroImg] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [nextSteps, setNextSteps] = useState("");
  const[accountType, setAccountType] = useState("");

  useEffect(() => {
    homeDetailsApi(sessionStorage.getItem("userFinancialId"))
      .then((res) => {
        setUserHomeDetails(res.home[0]);
        setUserHomeStatus(res.homeStatus[0]);
        setHeroImg(res.heroImage);
        setUserMemoryBookPhotos(res.memoryBookPhotos);
        setUserMemoryBookDetails(res.memoryBookDetails);
        setNextSteps(res.nextSteps);

        // User Data Temprorary
        setAccountType(sessionStorage.getItem("accountType"));
      })
      .catch((e) => {});
  }, []);
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Home | My Stanley Martin</title>
      </Helmet>
      <Hero img={heroImg} accountType={accountType} />
      {accountType === "3" ? (
        <Container className="pb-50 px-30 px-md-50" fluid>
          <WarrantyContent />
        </Container>
      ) : (
        <>
          <Container
            className="bg-light pt-30 pt-md-55 pb-30 px-15 px-md-50"
            fluid
          >
            <Col xs={12} className="me-auto p-0 max-view-width">
              <Row xs={1} md={3} className="g-4 mb-30 mb-md-0">
                <Col key={1} xs={12} md={6} lg={4}>
                  <HomeDetailCard
                    props={userHomeDetails}
                    cardTitle="My Home"
                    showAddress={false}
                    showCommunityDetails
                  />
                </Col>
                <Col key={2} xs={12} md={6} lg={3}>
                  <h3 className="mb-0">Key Dates</h3>
                  <HomeStatusCard props={userHomeStatus} />
                </Col>
                <Col key={3} xs={12} md={12} lg={4}>
                  <h3 className="text-nowrap mt-0 pt-md-0 mb-0">Quick Links</h3>
                  <HomeScheduleCard props={userHomeStatus} />
                </Col>
              </Row>
            </Col>
          </Container>

          {/*<Container
            className="bg-white pt-30 pt-md-55 pb-30 pb-md-50 px-30 px-md-50"
            fluid
          >
           <Container className="m-0 p-0">
              <Row>
                <Col className="text-center">
                  <Col sm={12} md={10} className="px-30 px-md-60 my-30 m-auto">
                    <h3 className="mb-20">Your Next Steps</h3>
                    <p>
                      Based on the phase of construction your home is in when
                      you purchase it, you will attend some or all of these
                      meetings. Scroll through the steps below to understand
                      which meetings you will attend. Once you've reached a
                      certain construction status on your home, you will be able
                      to schedule certain appointments. Talk with your
                      Neighborhood Sales Manager to understand what your next
                      steps are for your new home.
                    </p>
                  </Col>
                  <Col sm={12}>
                    <Slider
                      props={nextSteps}
                      recordDetails={""}
                      showTimeStamp={false}
                      showImg={false}
                      showText={true}
                      slide
                      fade={false}
                      showIndicators={false}
                      showControls={true}
                      rowSm={1}
                      rowMd={4}
                      rowLg={4}
                      showBackground
                      buttonText="SCHEDULE"
                      variant="success"
                    />
                  </Col>
                </Col>
              </Row>
            </Container>
          </Container>

                        <Container
            className="bg-light p-30 pb-30 pb-md-50 pe-30 pe-md-50 mb-n50"
            fluid
          >
            <Container className="m-0 p-0">
              <Row className="text-center text-primary mt-50">
                <Col xs={12} md={6} className="mb-10 m-auto">
                  <h3 className="mb-20">My Home Memory Book</h3>
                  <p>
                    Here you can view the progress on your home as it's being
                    built! Your Neighborhood Sales Consultant will update photos
                    as they become available.
                  </p>
                </Col>
                <Col xs={12}>
                  <Slider
                    props={userMemoryBookPhotos}
                    recordDetails={userMemoryBookDetails}
                    showTimeStamp={true}
                    showImg={true}
                    showText={false}
                    slide={false}
                    fade
                    rowSm={1}
                    rowMd={1}
                    rowLg={1}
                    showBackground={false}
                  />
                </Col>
              </Row>
            </Container>
          </Container>*/}
        </>
      )}
    </Page>
  );
}
